import React, { useState } from "react";
import logo from "../../images/logoFincrea.png";
import { Form, Card, Button, Icon, Header, Image } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHttpClient } from "../../util/http-hook";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import RecuperaPassword from "../components/RecuperaPassword";

const loginSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

const Login = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [recupera, setRecupera] = useState()
  let isSubmit = false;
  const history = useHistory();

  const handleClose = () => {
    setRecupera(false)
  }

  const loginData = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        isSubmit = true;
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/autenticazione/accedi",
          "POST",
          JSON.stringify({
            email: values.email,
            password: values.password,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        authService.login(
          responseData.email,
          responseData.token,
          responseData.ruolo
        );

        history.push("/");
        history.go(0);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error.message,
        });
        isSubmit = false;
      }
    },
  });

  return (
    <React.Fragment>
      {recupera && (
        <RecuperaPassword handleClose={handleClose} email={loginData.values.email} />
      )}
      <Image src={logo} centered rounded className="mb-5" />
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading || isSubmit}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {!isLoading && !isSubmit && (
        <div style={{ margin: "10%" }}>
          <Card color="blue" centered fluid>
            <Card.Header>
              <Header as="h2" className="text-center my-3">
                Accedi
              </Header>
            </Card.Header>
            <Card.Content>
              <Form size="large">
                <Form.Input
                  label="Email:"
                  id="email"
                  value={loginData.values.email}
                  onChange={loginData.handleChange}
                  onBlur={loginData.handleBlur}
                  error={loginData.errors.email && loginData.touched.email}
                />
                <Form.Input
                  label="Password:"
                  id="password"
                  type="password"
                  value={loginData.values.password}
                  onChange={loginData.handleChange}
                  onBlur={loginData.handleBlur}
                  error={
                    loginData.errors.password && loginData.touched.password
                  }
                />
                <label type="button" style={{ color: "gray", fontSize: "70%" }} onClick={() => setRecupera(true)}>
                   Password dimenticata?
                </label>
                <Button
                  type="submit"
                  color="blue"
                  floated="right"
                  onClick={loginData.handleSubmit}
                >
                  <Icon name="sign in" /> Accedi
                </Button>
              </Form>
            </Card.Content>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default Login;
