import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import {
  Button,
  Form,
  Icon,
  Menu,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import Loader from "react-loader-spinner";

import DettagliPersonali from "../components/DettagliPersonali";
import DettagliAmministrazione from "../components/DettagliAmministrazione";
import DettagliFinanziamenti from "../components/DettagliFinanziamenti/FinanziamentiList";
import DettagliNote from "../components/DettagliNote/NoteList";
import OpportunitaContattoList from "../components/DettagliOpportunità/OpportunitaContattoList";
import DettagliAllegati from "../components/DettagliFile/FileList";
import authService from "../../services/authService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useReactToPrint } from "react-to-print";
import StampaContatto from "../components/StampaContatto";

const emailSchema = Yup.object().shape({
  testo: Yup.string().required("Richiesto"),
});

const DettagliContatto = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const componentRef = useRef();
  const [open, setOpen] = useState(false);
  const token = authService.getCurrentToken();
  const [activeItem, setActiveItem] = useState("personale");
  const history = useHistory();
  const [infoContatto, setInfoContatto] = useState();
  const id_contatto = useParams().id_contatto;

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDelete = () => {
    Swal.fire({
      icon: "question",
      title: "Vuoi cancellare questo contatto?",
      text: "Cancellando il contatto perderai tutte le note e i fananziamenti a questo associati, continuare?",
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Conferma",
      denyButtonText: "Annulla",
    }).then(async (result) => {
      if (result.value) {
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL + "/contatti/eliminaContatto",
            "DELETE",
            JSON.stringify({
              id_contatto: id_contatto,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Eliminazione avvenuta",
            text: "Il contatto è stato eliminato correttamente",
          });
          history.push("/contatti");
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: err,
          });
        }
      }
    });
  };

  const email = useFormik({
    initialValues: {
      oggetto: "",
      testo: "",
    },
    validationSchema: emailSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL + `/contatti/inviaEmail/`,
          "POST",
          JSON.stringify({
            email: infoContatto.infoPersonali.email,
            oggetto: values.oggetto,
            testo: values.testo,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Email inviata correttamente.",
        }).then(() => setOpen(false));
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    },
  });

  useEffect(() => {
    const ottieniInfo = async () => {
      try {
        const info = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/contatti/infoContatto/${id_contatto}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setInfoContatto(info);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
          timer: 2000,
        }).then(() => {});
      }
    };
    ottieniInfo();
  }, [id_contatto, sendRequest, token]);

  return (
    <React.Fragment>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Invia email <Icon name="mail" />
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description className="text-center">
            <Form>
              <Form.Input
                label="Oggetto"
                id="oggetto"
                placeholder="Oggetto"
                value={email.values.oggetto}
                onChange={email.handleChange}
                onBlur={email.handleBlur}
              />
              <TextArea
                id="testo"
                placeholder="Testo email"
                type="text"
                error={email.errors.testo && email.touched.testo}
                onChange={email.handleChange}
                onBlur={email.handleBlur}
                value={email.values.testo}
              />
            </Form>
          </Modal.Description>
          <Modal.Actions>
            <Button
              positive
              className="float-right mt-md-2"
              onClick={email.handleSubmit}
            >
              Invia email
            </Button>
          </Modal.Actions>
        </Modal.Content>
      </Modal>

      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />

      {!isLoading && infoContatto && (
        <div className="card my-2 mx-3">
          <div>
            <div
              className="card-header border-0"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="row">
                <div
                  className="col-sm-12 col-lg-4 mb-2"
                  style={{ fontWeight: 600, fontSize: "120%" }}
                >
                  {infoContatto.infoPersonali.cognome}{" "}
                  {infoContatto.infoPersonali.nome}
                </div>
                <div className="col-sm-6 col-lg-4 text-lg-center my-auto">
                  <label
                    className="mr-3"
                    role="button"
                    onClick={() => setOpen(true)}
                    style={{ fontSize: "115%" }}
                  >
                    <Icon circular name="mail" />
                  </label>
                  <a
                    className="mr-3"
                    style={{ textDecoration: "none", color: "black" }}
                    href={`https://wa.me/39${infoContatto.infoPersonali.cellulare_1}`}
                  >
                    <Icon
                      style={{ fontSize: "115%" }}
                      circular
                      name="whatsapp"
                    />
                  </a>
                  {isMobile && (
                    <React.Fragment>
                      <a
                        className="mr-3"
                        style={{ textDecoration: "none", color: "black" }}
                        href={`sms:${infoContatto.infoPersonali.cellulare_1}`}
                      >
                        <Icon
                          style={{ fontSize: "115%" }}
                          circular
                          name="talk"
                        />
                      </a>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: "115%",
                        }}
                        href={`tel:${infoContatto.infoPersonali.cellulare_1}`}
                      >
                        <Icon
                          style={{ fontSize: "95%" }}
                          circular
                          name="call"
                        />
                      </a>
                    </React.Fragment>
                  )}
                </div>
                <div
                  className="col-sm-6 col-lg-4 text-lg-right"
                  style={{ fontWeight: 600 }}
                >
                  {!isLoading && infoContatto && (
                    <div>
                      <div style={{ display: "none" }}>
                        <StampaContatto
                          infoContatto={infoContatto}
                          id_contatto={id_contatto}
                          ref={componentRef}
                        />
                      </div>
                      <label
                        className="mr-2"
                        onClick={handlePrint}
                        role="button"
                      >
                        <FontAwesomeIcon icon={faPrint} /> Stampa
                      </label>
                      {authService.getCurrentRuolo() === "amministratore" && (
                        <label onClick={handleDelete} role="button">
                          <FontAwesomeIcon icon={faTrash} /> Elimina
                        </label>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card-body">
              <div>
                <span
                  role="button"
                  style={{ fontWeight: 600 }}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Indietro
                </span>
              </div>
              <Menu stackable tabular>
                <Menu.Item
                  name="personale"
                  active={activeItem === "personale"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="amministrazione"
                  active={activeItem === "amministrazione"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="finanziamenti"
                  active={activeItem === "finanziamenti"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="note"
                  active={activeItem === "note"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="opportunita"
                  active={activeItem === "opportunita"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="allegati"
                  active={activeItem === "allegati"}
                  onClick={handleItemClick}
                  color="blue"
                />
              </Menu>
              <Segment attached="bottom">
                <div className="row"></div>
                {activeItem === "personale" && (
                  <DettagliPersonali
                    info={infoContatto.infoPersonali}
                    id_contatto={id_contatto}
                  />
                )}
                {activeItem === "amministrazione" && (
                  <DettagliAmministrazione
                    info={infoContatto.infoLavorative}
                    data_assunzione={infoContatto.infoPersonali.data_assunzione}
                    sede_lavorativa={infoContatto.infoPersonali.sede_lavorativa}
                    id_contatto={id_contatto}
                  />
                )}
                {activeItem === "finanziamenti" && (
                  <DettagliFinanziamenti
                    info={infoContatto.infoFinanziamenti}
                    id_contatto={id_contatto}
                    cognome={infoContatto.infoPersonali.cognome}
                    nome={infoContatto.infoPersonali.nome}
                  />
                )}
                {activeItem === "note" && (
                  <DettagliNote
                    info={infoContatto.infoNote}
                    id_contatto={id_contatto}
                  />
                )}
                {activeItem === "opportunita" && (
                  <OpportunitaContattoList
                    info={infoContatto.infoOpportunita}
                    id_contatto={id_contatto}
                  />
                )}
                {activeItem === "allegati" && (
                  <DettagliAllegati
                    info={infoContatto.infoAllegati}
                    id_contatto={id_contatto}
                  />
                )}
              </Segment>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DettagliContatto;
