import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";

import FinanziamentiItem from "../../contatti/components/DettagliFinanziamenti/FinanziamentiItem";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import "../../App.css";

const RicercaDate = (props) => {
  const [risultatiRicerca, setRisultati] = useState(props.finanziamenti);
  const [dataInizio, setDataInizio] = useState(moment());
  const [dataFine, setDataFine] = useState(moment());

  //Paginazione
  const elementiPerPagina = 10;
  const [numeroPagine, setNumeroPagine] = useState(
    Math.ceil(props.finanziamenti.length / elementiPerPagina)
  );
  const [risultatiPagina, setRisultatiPagina] = useState();
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;
    console.log(offset);

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  //Fine paginazione

  const resetDate = () => {
    setRisultati(props.finanziamenti);
    setDataInizio(moment());
    setDataFine(moment());
    const pagina = {};
    pagina.selected = 0;
    handlePageChange(pagina);
  };

  useEffect(() => {
    setNumeroPagine(Math.ceil(risultatiRicerca.length / elementiPerPagina));

    const risultatiPagina = risultatiRicerca.slice(
      offset,
      offset + elementiPerPagina
    );
    setRisultatiPagina(risultatiPagina);
  }, [risultatiRicerca, offset]);

  const filtraPerDate = () => {
    const risultati = props.finanziamenti
      .filter(
        (finanziamento) =>
          moment(finanziamento.data_decorrenza).isSameOrAfter(
            dataInizio,
            "day"
          ) &&
          moment(finanziamento.data_decorrenza).isSameOrBefore(dataFine, "day")
      )
      .sort((a, b) => (a.data_decorrenza < b.data_decorrenza ? 1 : -1));

    const pagina = {};
    pagina.selected = 0;
    handlePageChange(pagina);

    setRisultati(risultati);
  };

  return (
    <div>
      <div className="mb-3">
        <Form unstackable className>
          <Form.Group widths="equal">
            <Form.Field>
              <label style={{ fontWeight: 700 }}>Data inizio periodo:</label>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  size="large"
                  clearable
                  cancelLabel="Cancella"
                  clearLabel="Annulla"
                  name="data_inizio"
                  value={dataInizio}
                  onChange={setDataInizio}
                  format={"DD-MM-YYYY"}
                />
              </MuiPickersUtilsProvider>
            </Form.Field>
            <Form.Field>
              <label style={{ fontWeight: 700 }}>Data fine periodo:</label>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale={"it"}
              >
                <KeyboardDatePicker
                  fullWidth
                  size="large"
                  clearable
                  cancelLabel="Cancella"
                  clearLabel="Annulla"
                  name="data_inizio"
                  value={dataFine}
                  onChange={setDataFine}
                  format={"DD-MM-YYYY"}
                />
              </MuiPickersUtilsProvider>
            </Form.Field>
          </Form.Group>
          <Button color="red" size="medium" onClick={resetDate}>
            <FontAwesomeIcon className="mr-1" icon={faCalendarTimes} /> Reset
            date
          </Button>
          <Button
            color="blue"
            icon
            size="medium"
            onClick={filtraPerDate}
            className="float-right"
          >
            {" "}
            <FontAwesomeIcon className="mr-1" icon={faSearch} />
            Ricerca
          </Button>
        </Form>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />

      {risultatiPagina && risultatiPagina.length === 0 ? (
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            <p
              className="col card py-2 px-3 shadow mt-3"
              style={{ fontSize: "14px", borderRadius: "10px" }}
            >
              Non è stato trovato nessun finanziamento per le date selezionate.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <ul className=" p-0 my-2 mx-3">
            {risultatiPagina &&
              risultatiPagina
                .sort((a, b) =>
                  a.data_decorrenza < b.data_decorrenza ? 1 : -1
                )
                .map((finanziamento) => (
                  <FinanziamentiItem
                    finanziamento={finanziamento}
                    key={finanziamento.id_finanziamento}
                    id_finanziamento={finanziamento.id_finanziamento}
                    data_decorrenza={finanziamento.data_decorrenza}
                    data_scadenza={finanziamento.data_scadenza_finanziamento}
                    tipologia={finanziamento.operazione.descrizione_operazione}
                    proprietario={finanziamento.tipologia}
                    cognome_collaboratore={finanziamento.collaboratore.cognome}
                    nome_collaboratore={finanziamento.collaboratore.nome}
                    nome_cliente={finanziamento.contatto.nome}
                    cognome_cliente={finanziamento.contatto.cognome}
                  />
                ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RicercaDate;
