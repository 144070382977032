import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";
import ContactList from "../../contatti/components/ContactsList";

/*
Utilizzo gli id_operazioni del db,
1 => CQS,
2 => DLG,
3 => Doppia DLG
*/

const elementiPerPagina = 10;

const ContattiAmministrazione = (props) => {
    const { sendRequest, isLoading } = useHttpClient();
  const token = authService.getCurrentToken();
  const [contatti, setContatti] = useState();
  const [risultati, setRisultati] = useState([])

  //Paginazione
  const [numeroPagine, setNumeroPagine] = useState();
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  useEffect(() => {
    if (contatti) {
        const risultati = contatti.sort((a, b) => (a.cognome > b.cognome ? 1 : -1));
        const risultatiPagina = risultati.slice(
            offset,
            offset + elementiPerPagina
        );
        setRisultati(risultatiPagina);
    }
  }, [contatti, offset]);


  useEffect(() => {
    const ottieniContatti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/ottieniContattiAmministrazione/" + props.id_amministrazione,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setContatti(responseData);
        setNumeroPagine(
          Math.ceil(responseData.length / elementiPerPagina)
        );
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniContatti()
  }, [sendRequest, token]);

  return (
    <div>
        <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      {!isLoading && risultati.length && numeroPagine && (
        <React.Fragment>
          <ContactList items={risultati} />
        </React.Fragment>
      )}
    </div>
  );
};

export default ContattiAmministrazione;
