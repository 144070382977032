import firebase from "firebase/app";
import "firebase/messaging";
import authService from "./authService";
const token = authService.getCurrentToken();

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDbv4RSo8xmOYeSmA-q5LIBJT5WvkP0p3I",
  authDomain: "fincrea-e966c.firebaseapp.com",
  projectId: "fincrea-e966c",
  storageBucket: "fincrea-e966c.appspot.com",
  messagingSenderId: "326657150497",
  appId: "1:326657150497:web:2cc6a716e9ef1bafdddd60",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
let messaging;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer()) {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL + "/token/aggiungiToken";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          token: currentToken,
          email: authService.getCurrentEmail(),
          type: 'PC'
        }),
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((data) => console.log(data));

      setTokenSentToServer(true);
    } catch (error) {
      console.log(error);
    }
  }
}

function isTokenSentToServer() {
  return window.localStorage.getItem("sentToServer") === "1";
}

function setTokenSentToServer(sent) {
  window.localStorage.setItem("sentToServer", sent ? "1" : "0");
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (messaging) {
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            sendTokenToServer(currentToken);
          } else {
            // Show permission request.
            console.log(
              "No registration token available. Request permission to generate one."
            );
            setTokenSentToServer(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });
