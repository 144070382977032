import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobile,
  faPhoneAlt,
  faSearchPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const ContactItem = (props) => {
  const history = useHistory();
  return (
    <div
      className="card shadow col-sm-9 mx-auto mt-4"
      style={{ border: "none", borderRadius: "10px" }}
    >
      <div
        className="d-flex justify-content-between mt-2 mb-3"
        style={{ fontSize: "105%" }}
      >
        <div style={props.tipo.toLowerCase() === 'lead' ? {color: "#FF4500"} : {}}>
          <FontAwesomeIcon icon={faUser} />{" "}
          <span style={ { fontWeight: 700 }} className="ml-1">
            {props.tipo}:
          </span>{" "}
          {props.cognome} {props.nome}
        </div>
        <div>
          <FontAwesomeIcon
            role="button"
            onClick={() =>
              history.push(`/dettagliContatto/${props.id_contatto}`)
            }
            icon={faSearchPlus}
            size="lg"
          />
        </div>
      </div>
      {props.cellulare && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faMobile} />{" "}
          <span className="ml-1">{props.cellulare}</span>
        </div>
      )}
      {props.telefono && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faPhoneAlt} />{" "}
          <span className="ml-1">{props.telefono}</span>
        </div>
      )}
      {props.email && (
        <div className="row mb-2">
          <div className="col-sm-12 col-lg-6 col-md-6 mb-3 mb-lg-0 mb-md-0">
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <span className="ml-1">{props.email}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactItem;
