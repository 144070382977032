import React, { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import { useHistory } from "react-router-dom";
import BackDrop from "../UIElements/Backdrop";
import "./MainNavigation.css";
import logo from "../../../images/logoFincrea.png";
import authService from "../../../services/authService";

const MainNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const history = useHistory();

  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };
  return (
    <React.Fragment>
      {drawerIsOpen && <BackDrop onClick={closeDrawer} />}
      <SideDrawer show={drawerIsOpen}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks onCloseDrower={closeDrawer} />
        </nav>
      </SideDrawer>
      ;
      <MainHeader>
        {authService.getCurrentToken() && (
          <div>
            <div className="burger-menu__navigation">
              <Hamburger
                toggled={drawerIsOpen}
                toggle={setDrawerIsOpen}
                color="#385898"
              />
            </div>
            <nav className="main-navigation__header-nav">
              <NavLinks />
            </nav>
           
          </div>
        )}

        <img
          src={logo}
          alt={''}
          className="img-fluid ml-auto"
          style={{ width: "150px" }}
          onClick={() => {
            history.push("/");
          }}
        />
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
