import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";

import FileItem from "./FileItem";
import DocumentoUpload from "../../../services/DocumentoUpload";
import { useFormik } from "formik";
import { useHttpClient } from "../../../util/http-hook";
import * as Yup from "yup";
import authService from "../../../services/authService";
import Swal from "sweetalert2";

const fileSchema = Yup.object().shape({
  nome_file: Yup.string().required(),
});

const FileList = (props) => {
  const [open, setOpen] = useState(false);
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  const fileForm = useFormik({
    initialValues: {
      nome_file: "",
      file: "",
    },
    validationSchema: fileSchema,
    onSubmit: async (values) => {
      if (values.file) {
        const formData = new FormData();
        formData.append("nome_file", values.nome_file);
        formData.append("ref_contatto", props.id_contatto);
        formData.append("file", values.file);
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BASE_URL + "/contatti/aggiungiAllegato",
            "POST",
            formData,
            {
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "File caricato correttamente",
          }).then(() => {
            props.info.push(responseData)
            setOpen(false)
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Selezionare un file",
        });
      }
    },
  });

  const handleChange = (id, file) => {
    fileForm.setFieldValue(id, file);
    fileForm.setFieldValue("nome_file", file.name);
  };

  return (
    <React.Fragment>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">Aggiungi nuovo file</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <div className="mb-2">
                <DocumentoUpload
                  id="file"
                  label="Aggiungi file"
                  onInput={(id, file) => handleChange(id, file)}
                />
              </div>
              <Form.Input
                label="Nome documento"
                id="nome_file"
                value={fileForm.values.nome_file}
                onChange={fileForm.handleChange}
                onBlur={fileForm.handleBlur}
                error={fileForm.touched.nome_file && fileForm.errors.nome_file}
              />
              <Button
                type="submit"
                positive
                floated="right"
                onClick={fileForm.handleSubmit}
              >
                <Icon name="save" />
                Salva allegato
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div>
        <div className="row">
          <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
            <label
              role="button"
              style={{ fontWeight: 600 }}
              onClick={() => setOpen(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Aggiungi file
            </label>
          </div>
        </div>
        {props.info.length === 0 ? (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non ci sono allegati per questo contatto. Aggiungine
                uno nuovo tramite l'apposito tasto.
              </p>
            </div>
          </div>
        ) : (
          <div>
            <ul className=" p-0 my-2 mx-3">
              {props.info
                .sort((a, b) => (a.createdat < b.createdat ? 1 : -1))
                .map((allegato) => (
                  <FileItem key={allegato.id_allegato} allegato={allegato} />
                ))}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FileList;
