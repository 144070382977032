import React from "react";

import FianziamentiItem from "../../contatti//components/DettagliFinanziamenti/FinanziamentiItem";
const ListaFinanziamenti = (props) => {
  return (
    <React.Fragment>
      <div>
        {props.finanziamenti.length === 0 ? (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non sono presenti finanziamenti. Inseriscine uno
                tramite l'apposito bottone.
              </p>
            </div>
          </div>
        ) : (
          <div>
            <ul className=" p-0 my-2 mx-3">
              {props.finanziamenti.sort((a,b) => (a.data_decorrenza < b.data_decorrenza ? 1 : -1)).map((finanziamento) => (
                <FianziamentiItem
                  finanziamento={finanziamento}
                  key={finanziamento.id_finanziamento}
                />
              ))}
            </ul>
            <br></br>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ListaFinanziamenti;
