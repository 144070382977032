import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHttpClient } from "../../util/http-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import "../../App.css";

import ContactList from "../components/ContactsList";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import AggiungiContatto from "../components/AggiungiContatto";
import authService from "../../services/authService";
import Loader from "react-loader-spinner";


const PaginaContatti = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [contatti, setContatti] = useState();
  const [numeroLead, setNumeroLead] = useState();
  const [numeroClienti, setNumeroClienti] = useState();
  const token = authService.getCurrentToken();
  const [open, setOpen] = useState(false);
  const [risultatiRicerca, setRisultati] = useState();
  const history = useHistory();

  //Paginazione
  const [numeroPagine, setNumeroPagine] = useState();
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);
  const elementiPerPagina = 10;

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  //Fine paginazione

  const aggiungiContatto = (contatto) => {
    setContatti((contatti) => [...contatti, contatto]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkValido = async function () {
      const email = authService.getCurrentEmail();
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + `/utenti/controllaValido/${email}`,
          "GET",
          null
        );
        if (!responseData) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text:
              "Problemi con i dati, provare a effettuare nuovamente il login.",
          }).then(() => {
            authService.logout();
            history.go(0);
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        }).then(() => authService.logout());
      }
    };
    if (token) {
      checkValido();
    }
  }, [token, sendRequest, history]);

  useEffect(() => {
    const ottieniContatti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setNumeroLead(responseData.numeroLead);
        setNumeroClienti(responseData.numeroClienti);
        setContatti(responseData.listaContatti);
        setNumeroPagine(
          Math.ceil(responseData.listaContatti.length / elementiPerPagina)
        );
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    const ottieniLead = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/ottieniLead",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setContatti(responseData.listaLead);
        setNumeroLead(responseData.numeroLead);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    if (authService.getCurrentRuolo() === "collaboratore") {
      ottieniLead();
    }
    if (authService.getCurrentRuolo() === "amministratore") {
      ottieniContatti();
    }
  }, [sendRequest, token]);

  const formik = useFormik({
    initialValues: {
      ricerca: "",
    },
  });

  useEffect(() => {
    if (contatti) {
      const risultati = contatti
        .filter(
          (contatto) =>
            contatto.cognome &&
            contatto.cognome
              .toLowerCase()
              .includes(formik.values.ricerca.toLowerCase())
        )
        .sort((a, b) => (a.cognome > b.cognome ? 1 : -1));

      if (risultati.length) {
        setNumeroPagine(Math.ceil(risultati.length / elementiPerPagina));
      }

      if (formik.values.ricerca) {
        const pagina = {};
        pagina.selected = 0;
        handlePageChange(pagina);
      }

      const risultatiPagina = risultati.slice(
        offset,
        offset + elementiPerPagina
      );
      setRisultati(risultatiPagina);
    }
  }, [formik.values.ricerca, contatti, offset]);

  return (
    <React.Fragment>
      {open && contatti && (
        <AggiungiContatto
          contatti={contatti}
          handleClose={handleClose}
          aggiungiContatto={aggiungiContatto}
        />
      )}
      <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
        <div
          className="card-header border-0"
          style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
        >
          <h3 className="mb-0 text-center">Contatti</h3>
        </div>

        <div className="card-body">
          <div className="float-left align-middle mt-2 col-md-6 col-lg-6 col-sm-12  p-0">
            <div className="row">
              <label
              className="col-sm-12 col-md-6"
                style={{ fontWeight: 500 }}
                role="button"
                onClick={() => setOpen(true)}
              >
                <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                Aggiungi contatto
              </label>
              <div className="col-sm-12 col-md-6">
                {numeroClienti &&
                  authService.getCurrentRuolo().toLowerCase() ===
                    "amministratore" && (
                    <label className="ml-sm-3">
                      <span style={{ fontWeight: 500 }}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Clienti: {numeroClienti}
                      </span>
                    </label>
                  )}
                {numeroLead && (
                  <label className="ml-3" style={{ color: "#FF4500" }}>
                    <span style={{ fontWeight: 500 }}>
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      Lead: {numeroLead}
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
          <form>
            <div className="form-group row col-md-6 col-lg-6 col-sm-12 ">
              <label
                htmlFor="ricerca"
                className="col-sm-12 col-md-3 col-lg-3 mt-2 p-0 text-lg-right mr-1"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-1  p-0"
                ></FontAwesomeIcon>
                Ricerca
              </label>
              <div className="col-sm-8 ml-auto p-0">
                <input
                  id="ricerca"
                  className="form-control text-center"
                  name="ricerca"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ricerca}
                  style={{ borderRadius: "500rem" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      {!isLoading && risultatiRicerca && numeroPagine && (
        <React.Fragment>
          <ContactList items={risultatiRicerca} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PaginaContatti;
