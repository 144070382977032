import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faFolderOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { PDFReader } from "reactjs-pdf-reader";
import { Modal } from "semantic-ui-react";
import { useHttpClient } from "../../../util/http-hook";
import authService from "../../../services/authService";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const FileItem = (props) => {
  const [visibile, setVisibile] = useState(false);
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const history = useHistory();

  const eliminaAllegato = async function () {
    try {
      await sendRequest(
        process.env.REACT_APP_BASE_URL + "/contatti/eliminaAllegato",
        "DELETE",
        JSON.stringify({
          id_allegato: props.allegato.id_allegato,
          path: props.allegato.path,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "File eliminato correttamente!",
      }).then(() => {
        history.go(0);
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: error,
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        onClose={() => setVisibile(false)}
        onOpen={() => setVisibile(true)}
        open={visibile}
        style={{ position: "relative", height: "auto", width: "auto" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <PDFReader
              url={process.env.REACT_APP_BASE_URL + "/" + props.allegato.path}
              showAllPage
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <div
        className="card shadow col-sm-9 mx-auto mt-4"
        style={{ border: "none", borderRadius: "10px" }}
      >
        <div className="mb-3 mt-2">
          <span style={{ fontWeight: 600, fontSize: "110%" }}>
            <FontAwesomeIcon className="mr-2" icon={faFilePdf} size="lg" />
            {props.allegato.nome_file}
          </span>
          <a
            onClick={eliminaAllegato}
            target="_blank"
            rel="noreferrer"
            className="float-right ml-2"
          >
            <FontAwesomeIcon icon={faTrash} size="lg" color="red" />
          </a>
          <a
            href={process.env.REACT_APP_BASE_URL + "/" + props.allegato.path}
            target="_blank"
            rel="noreferrer"
            className="float-right"
          >
            <FontAwesomeIcon icon={faFolderOpen} size="lg" />
          </a>
        </div>
        <div className="mb-3">
          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
          <label style={{ fontWeight: 600 }} className="ml-1">
            Data caricamento:
          </label>{" "}
          {moment(props.allegato.createdat).format("DD/MM/yyyy HH:mm")}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileItem;
