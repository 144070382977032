import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, TextArea, Dropdown, Icon } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import { useHttpClient } from "../../util/http-hook";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import authService from "../../services/authService";

moment.locale("it");

const opportunitaSchema = Yup.object().shape({
  ref_attivita: Yup.number().required("Richiesto"),
  data_opportunita: Yup.date().required("Richiesto"),
});

const AggiungiOpportunitaContatto = (props) => {
  const history = useHistory();
  const [attivita, setAttivita] = useState();
  const token = authService.getCurrentToken();
  const { sendRequest } = useHttpClient();
  const [selectedDate, setDate] = useState(new Date());


  const handleDateChange = (data) => {
    setDate(data);
    informazioni.setFieldValue("data_opportunita", data);
  };

  useEffect(() => {
    const ottieniAttivita = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/attivita/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const attivita_response = [];
        responseData.forEach((attivita) => {
          attivita_response.push({
            key: attivita.id_attivita,
            value: attivita.id_attivita,
            text: attivita.descrizione_attivita,
            icon: attivita.icona,
          });
        });
        setAttivita(attivita_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    ottieniAttivita();
  }, [sendRequest, token]);

  const informazioni = useFormik({
    initialValues: {
      ref_attivita: "",
      note_opportunita: "",
      data_opportunita: new Date(),
    },
    validationSchema: opportunitaSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + `/opportunita/aggiungiOpportunita/`,
          "POST",
          JSON.stringify({
            ref_contatto: props.id_contatto,
            ref_attivita: values.ref_attivita,
            note_opportunita: values.note_opportunita,
            data_opportunita: values.data_opportunita,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazione completata correttamente",
          text: "Opportunità aggiunta correttamente",
        });
        props.aggiungiOpportunita(responseData);
        props.handleClose();
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        }).then(() => history.go(0));
      }
    },
  });

  return (
    <div style={{ fontSize: "110%" }}>
      <Form>
        <Form.Field required>
          <label>Attività</label>
          <Dropdown
            error={
              informazioni.errors.ref_attivita &&
              informazioni.touched.ref_attivita
            }
            placeholder="Attività"
            fluid
            selection
            value={informazioni.values.ref_attivita}
            options={attivita}
            onChange={(e, data) =>
              informazioni.setFieldValue("ref_attivita", data.value)
            }
            onBlur={() => informazioni.setFieldTouched("ref_attivita")}
          />
        </Form.Field>

        <Form.Field>
          <label>Note</label>
          <TextArea
            id="note_opportunita"
            value={informazioni.values.note_opportunita}
            onChange={informazioni.handleChange}
            onBlur={informazioni.handleBlur}
          />
        </Form.Field>
        <Form.Field className="mb-4" required>
          <label style={{ fontWeight: 700 }}>Data decorrenza</label>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={"it"}
          >
            <DateTimePicker
              fullWidth
              ampm={false}
              size="large"
              clearable
              cancelLabel="Cancella"
              error={
                informazioni.errors.data_opportunita &&
                informazioni.touched.data_opportunita
              }
              clearLabel="Annulla"
              id="data_opportunita"
              value={selectedDate}
              onBlur={informazioni.handleBlur}
              onChange={(date) => handleDateChange(date)}
              format="DD/MM/yyyy HH:mm"
            />
          </MuiPickersUtilsProvider>
        </Form.Field>
        <Button
          icon
          type="submit"
          positive
          floated="right"
          onClick={informazioni.handleSubmit}
        >
          <Icon name="save" /> Salva
        </Button>
      </Form>
    </div>
  );
};

export default AggiungiOpportunitaContatto;
