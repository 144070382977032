import { Form } from "semantic-ui-react";
import React, {useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import SelezioneAmministrazione from "./SelezioneAmministrazione";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />;
};

const DettagliAmministrazione = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {open && (
        <SelezioneAmministrazione
          id_contatto={props.id_contatto}
          handleClose={handleClose}
        />
      )}
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            style={{ fontWeight: 600 }}
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={faExchangeAlt} /> Cambia amministrazione
          </label>
        </div>
      </div>

      <div>
        <div style={{ fontSize: "110%" }}>
          <Form>
            <Form.Input
              label="Denominazione"
              value={props.info.denominazione}
              readOnly
            />
            <Form.Group widths="equal">
              <Form.Input
                label="Tipologia"
                value={
                  props.info.tipologia_amministrazione.descrizione_tipologia
                }
                readOnly
              />
              <Form.Input
                label="Sede lavorativa"
                value={
                  props.sede_lavorativa
                }
                readOnly
              />
              <Form.Field required>
                <label style={{ fontWeight: 700 }}>Data assunzione</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <KeyboardDatePicker
                    fullWidth
                    clearable
                    readOnly
                    cancelLabel="Cancella"
                    clearLabel="Annulla"
                    name="data"
                    value={props.data_assunzione}
                    format={"DD-MM-YYYY"}
                    TextFieldComponent={TextFieldComponent}
                  />
                </MuiPickersUtilsProvider>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input label="Città" value={props.info.citta} readOnly />
              <Form.Input
                label="Provincia"
                value={props.info.provincia}
                readOnly
              />
              <Form.Input label="CAP" value={props.info.cap} readOnly />
            </Form.Group>
            <Form.Input
              label="Indirizzo notifica cartacea"
              value={props.info.indirizzo_notifica_cartacea}
              readOnly
            />
            <Form.Group widths="equal">
              <Form.Input
                label="Nome responsabile 1"
                value={props.info.nome_resp_1}
                readOnly
              />
              <Form.Input
                label="Telefono responsabile 1"
                value={props.info.telefono_resp_1}
                readOnly
              />
              <Form.Input
                label="Email responsabile 1"
                value={props.info.email_resp_1}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Nome responsabile 2"
                value={props.info.nome_resp_2}
                readOnly
              />
              <Form.Input
                label="Telefono responsabile 2"
                value={props.info.telefono_resp_2}
                readOnly
              />
              <Form.Input
                label="Email responsabile 2"
                value={props.info.email_resp_2}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Indirizzo pec 1"
                value={props.info.indirizzo_pec_1}
                readOnly
              />
              <Form.Input
                label="Indirizzo pec 2"
                value={props.info.indirizzo_pec_2}
                readOnly
              />
            </Form.Group>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DettagliAmministrazione;
