import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";

import FinanziamentiItem from "../../contatti/components/DettagliFinanziamenti/FinanziamentiItem";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import authService from "../../services/authService";

import ReactPaginate from "react-paginate";
import "../../App.css";

const RicercaCollaboratore = (props) => {
  const [risultatiRicerca, setRisultati] = useState(props.finanziamenti);
  const [collaboratori, setCollaboratori] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  //Paginazione
  const elementiPerPagina = 10;
  const [numeroPagine, setNumeroPagine] = useState(
    Math.ceil(props.finanziamenti.length / elementiPerPagina)
  );
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;
    console.log(offset);

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  //Fine paginazione

  const handleChange = async function (value) {
    formik.setFieldValue("ricerca", value);
    const pagina = {};
    pagina.selected = 0;
    handlePageChange(pagina);
  };

  const formik = useFormik({
    initialValues: {
      ricerca: 1,
    },
  });
  useEffect(() => {
    const ottieniCollaboratori = async function () {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/collaboratori",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const collaboratoriArray = [];
        responseData.forEach((collaboratore) => {
          const valore = {
            key: collaboratore.id_collaboratore,
            text: collaboratore.cognome + " " + collaboratore.nome,
            value: collaboratore.id_collaboratore,
          };
          collaboratoriArray.push(valore);
        });

        setCollaboratori(collaboratoriArray);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniCollaboratori();
  }, [sendRequest, token]);

  useEffect(() => {
    const risultati = props.finanziamenti
      .filter(
        (finanziamento) =>
          finanziamento.ref_collaboratore === formik.values.ricerca
      )
      .sort((a, b) => (a.data_decorrenza < b.data_decorrenza ? 1 : -1));
    if (risultati.length) {
      setNumeroPagine(Math.ceil(risultati.length / elementiPerPagina));
    }

    const risultatiPagina = risultati.slice(offset, offset + elementiPerPagina);
    setRisultati(risultatiPagina);
  }, [formik.values.ricerca, props.finanziamenti, offset]);

  return (
    <div>
      <div>
        <Form unstackable className="mx-3">
          {!isLoading && collaboratori && (
            <Form.Field>
              <label>Seleziona collaboratore</label>
              <Dropdown
                placeholder="Collaboratore"
                name="collaboratore"
                search
                selection
                options={collaboratori}
                defaultValue={1}
                onChange={(e, data) => handleChange(data.value)}
                onBlur={() => formik.setFieldTouched("ricerca")}
              />
            </Form.Field>
          )}
        </Form>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />

      {risultatiRicerca && risultatiRicerca.length === 0 ? (
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            <p
              className="col card py-2 px-3 shadow mt-3"
              style={{ fontSize: "14px", borderRadius: "10px" }}
            >
              Non è stato trovato nessun finanziamento per il collaboratore
              selezionato.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <ul className=" p-0 my-2 mx-3">
            {risultatiRicerca &&
              risultatiRicerca
                .sort((a, b) =>
                  a.data_decorrenza < b.data_decorrenza ? 1 : -1
                )
                .map((finanziamento) => (
                  <FinanziamentiItem
                    finanziamento={finanziamento}
                    key={finanziamento.id_finanziamento}
                    id_finanziamento={finanziamento.id_finanziamento}
                    data_decorrenza={finanziamento.data_decorrenza}
                    data_scadenza={finanziamento.data_scadenza_finanziamento}
                    tipologia={finanziamento.operazione.descrizione_operazione}
                    proprietario={finanziamento.tipologia}
                    cognome_collaboratore={finanziamento.collaboratore && finanziamento.collaboratore.cognome}
                    nome_collaboratore={finanziamento.collaboratore && finanziamento.collaboratore.nome}
                    nome_cliente={finanziamento.contatto.nome}
                    cognome_cliente={finanziamento.contatto.cognome}
                  />
                ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RicercaCollaboratore;
