import {
  faAt,
  faCalendarAlt,
  faUserEdit,
  faUserMinus,
  faUsers,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import authService from "../../services/authService";
import { useHistory } from "react-router-dom";
import { Button, Form, Modal, Dropdown } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";

const utenteSchema = Yup.object().shape({
  email: Yup.string().required(),
  ruolo: Yup.string().required(),
});

const ruoli = [
  { key: 1, text: "Collaboratore", value: "collaboratore" },
  { key: 2, text: "Amministratore", value: "amministratore" },
];

const UtenteItem = (props) => {
  const { sendRequest } = useHttpClient();
  const [open, setOpen] = useState(false);
  const token = authService.getCurrentToken();
  const history = useHistory();

  const formUtente = useFormik({
    initialValues: {
      email: props.utente.email,
      ruolo: props.utente.ruolo,
    },
    validationSchema: utenteSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/utenti/modificaUtente/${props.utente.email}`,
          "PATCH",
          JSON.stringify({
            ruolo: values.ruolo,
            nuovaEmail: values.email,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Utente modificato correttamente. ",
          text: "Per vedere i cambiamenti di ruolo l'utente in questione deve accedere nuovamente."
        }).then(() => {
          history.go(0);
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    },
  });

  const eliminaUtente = () => {
    Swal.fire({
      icon: "question",
      title: "Sei sicuro di volere cancellare l'utente?",
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonText: "Conferma",
    }).then(async (response) => {
      if (response.isConfirmed) {
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL +
              `/utenti/eliminaUtente/${props.utente.email}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Utente eliminato correttamente",
          }).then(() => {
            history.go(0);
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      }
    });
  };



  return (
    <React.Fragment>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Modifica collaboratore <FontAwesomeIcon icon={faUsers} />{" "}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Form.Input
                id="email"
                label="Email"
                value={formUtente.values.email}
                onChange={formUtente.handleChange}
                onBlur={formUtente.handleBlur}
                error={formUtente.errors.email && formUtente.touched.email}
              />
              <Form.Field>
                <label>Ruolo</label>
                <Dropdown
                  selection
                  placeholder="Ruolo"
                  value={formUtente.values.ruolo}
                  fluid
                  onChange={(e, data) =>
                    formUtente.setFieldValue("ruolo", data.value)
                  }
                  onBlur={() => formUtente.setFieldTouched("ruolo")}
                  error={formUtente.errors.ruolo && formUtente.touched.ruolo}
                  options={ruoli}
                />
              </Form.Field>
            </Form>
            <Button
              positive
              className="float-right mt-md-3"
              onClick={formUtente.handleSubmit}
              type="submit"
            >
              Modifica Utente
            </Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div
        className="card shadow col-sm-9 mx-auto mt-4"
        style={{ border: "none", borderRadius: "10px" }}
      >
        <div
          className="d-flex justify-content-between mb-3 mt-2"
          style={{ fontSize: "105%" }}
        >
          <div>
            <FontAwesomeIcon icon={faAt} />{" "}
            <span
              style={{ fontWeight: 700, fontSize: "110%" }}
              className="ml-1"
            >
              {props.utente.email}
            </span>{" "}
          </div>
        </div>

        <div className="mb-3">
          <FontAwesomeIcon icon={faUserTag} />{" "}
          <span className="ml-1">{props.utente.ruolo}</span>
        </div>

        <div className="mb-3">
          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
          <span className="ml-1">
            {moment(props.utente.createdat).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="mb-3">
          <label
            role="button"
            onClick={() => setOpen(true)}
            style={{ fontWeight: "600" }}
            className="mr-3"
          >
            <FontAwesomeIcon
              className="mr-1"
              role="button"
              icon={faUserEdit}
              size="lg"
            />{" "}
            Modifica
          </label>
          <label
            role="button"
            style={{ fontWeight: "600" }}
            onClick={eliminaUtente}
          >
            <FontAwesomeIcon
              className="mr-1"
              role="button"
              icon={faUserMinus}
              size="lg"
            />{" "}
            Elimina
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UtenteItem;
