import {
  faBriefcase,
  faEnvelope,
  faHome,
  faMobile,
  faPassport,
  faSearchPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";

const CollaboratoriItem = (props) => {
  const history = useHistory();
  return (
    <div
      className="card shadow col-sm-9 mx-auto mt-4"
      style={{ border: "none", borderRadius: "10px" }}
    >
      <div
        className="d-flex justify-content-between mb-3 mt-2"
        style={{ fontSize: "105%" }}
      >
        <div>
          <FontAwesomeIcon icon={faUsers} />{" "}
          <span style={{ fontWeight: 700, fontSize: "110%" }} className="ml-1">
            {props.cognome} {props.nome}
          </span>{" "}
        </div>
        <div>
          <FontAwesomeIcon
            role="button"
            icon={faSearchPlus}
            size="lg"
            onClick={() =>
              history.push(`/dettagliCollaboratore/${props.id_collaboratore}`)
            }
          />
        </div>
      </div>
      <div className="mb-3">
        <FontAwesomeIcon icon={faMobile} />{" "}
        <span className="ml-1">{props.cellulare_1}</span>
      </div>
      {props.telefono_casa && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faHome} />{" "}
          <span className="ml-1">{props.telefono_casa}</span>
        </div>
      )}
      {props.telefono_ufficio && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faBriefcase} />{" "}
          <span className="ml-1">{props.telefono_ufficio}</span>
        </div>
      )}
      <div className="mb-3">
        <FontAwesomeIcon icon={faEnvelope} />{" "}
        <span className="ml-1">{props.email}</span>
      </div>
      {props.partita_iva && (
        <div className="mb-2">
          <FontAwesomeIcon icon={faPassport} />{" "}
          <span className="ml-1">{props.partita_iva}</span>
        </div>
      )}
    </div>
  );
};

export default CollaboratoriItem;
