import React from "react";

import IstitutiItem from './IstitutiItem'

const IstitutiList = (props) => {
    if (props.items.length === 0) {
        return (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non ci sono amministrazioni, aggiungi una nuova amministrazione
                per visualizzarla qui.
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <ul className=" p-0 my-2 mx-3">
              {props.items.sort((a,b) => (a.denominazione > b.denominazione ? 1 : -1)).map((istituto) => (
                <IstitutiItem
                  key={istituto.id_istituto}
                  id_istituto={istituto.id_istituto}
                  denominazione={istituto.denominazione}
                  email={istituto.email}
                  indirizzo={istituto.indirizzo}
                  citta={istituto.citta}
                  provincia={istituto.provincia}
                  cap={istituto.cap}
                  recapito_1={istituto.recapito_1}
                  recapito_2={istituto.recapito_2}
                />
              ))}
            </ul>
            <br></br>
          </React.Fragment>
        );
      }
};

export default IstitutiList;
