import React from "react";

import OpportunitaItem from "./OpportunitaItem";

const OpportunitaList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="row mx-0 justify-content-center">
        <div className="col-12">
          <p
            className="col card py-2 px-3 shadow mt-3"
            style={{ fontSize: "14px", borderRadius: "10px" }}
          >
            Al momento non ci sono opportunità programmate, aggiungi una nuova
            opportunità per visualizzarla qui.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <ul className=" p-0 my-2 mx-3">
          {props.items
            .sort((a, b) => (a.data_opportunita > b.data_opportunita ? 1 : -1))
            .map((opportunita) => (
              <OpportunitaItem
                key={opportunita.id_opportunita}
                opportunita={opportunita}
                id_opportunita={opportunita.id_opportunita}
                ref_contatto={opportunita.ref_contatto}
                data={opportunita.data_opportunita}
                nome_attivita={opportunita.attivitum.descrizione_attivita}
                icona_attivita={opportunita.attivitum.icona}
                completata={opportunita.completata}
                note={decodeURIComponent(opportunita.note_opportunita)}
                esito={opportunita.esito_opportunita ? decodeURIComponent(opportunita.esito_opportunita) : opportunita.esito_opportunita}
                nome={opportunita.contatto.nome}
                cognome={opportunita.contatto.cognome}
              />
            ))}
        </ul>
        <br></br>
      </React.Fragment>
    );
  }
};

export default OpportunitaList;
