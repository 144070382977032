import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Menu, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";

import ModificaInformazioni from "../components/ModificaInformazioni";
import UtenteList from "../components/UtenteList";

const PaginaImpostazioni = () => {
  const [activeItem, setActiveItem] = useState("impostazioni");
  const { isLoading, sendRequest } = useHttpClient();
  const [utenti, setUtenti] = useState();
  const token = authService.getCurrentToken();
  const ruolo = authService.getCurrentRuolo();



  useEffect(() => {
    const ottieniUtenti = async () => {
      try {
        const info = await sendRequest(
          process.env.REACT_APP_BASE_URL + `/utenti/`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setUtenti(info);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniUtenti();
  }, [sendRequest, token]);

 

  const handleItemClick = (e, { id }) => setActiveItem(id);

  return (
    <React.Fragment>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {!isLoading && utenti && (
        <div>
          <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
            <div
              className="card-header border-0"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="float-left">
                <h4>
                  <span style={{ fontWeight: "700" }}>Account in uso:</span>{" "}
                  {authService.getCurrentEmail()}
                </h4>
              </div>
            </div>
            <Menu fluid pointing secondary stackable>
              <Menu.Item
                name="impostazioni"
                id="impostazioni"
                active={activeItem === "impostazioni"}
                onClick={handleItemClick}
              />
              {ruolo === "amministratore" && (
                <Menu.Item
                  name="utenti"
                  id="utenti"
                  active={activeItem === "utenti"}
                  onClick={handleItemClick}
                />
              )}
            </Menu>
            <div className="card-body">
              <Segment attached="bottom">
                <div className="row"></div>
                {activeItem === "impostazioni" && <ModificaInformazioni />}
                {ruolo === "amministratore" && activeItem === "utenti" && (
                  <UtenteList utenti={utenti} />
                )}
              </Segment>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PaginaImpostazioni;
