import {
  faCalendarDay,
  faCalendarTimes,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import moment from "moment";

const OpportunitaItem = (props) => {
  const data = moment(props.finanziamento.data_decorrenza).format("DD/MM/YYYY");
  return (
    <div
      className="card shadow  mx-auto my-3"
      style={{
        border: "none",
        borderRadius: "10px",
        backgroundColor: "Gainsboro",
      }}
    >
      <div className="mx-2">
        <div className="my-1">
          <span style={{ fontWeight: 600, fontSize: "120%" }}>
            {props.finanziamento.descrizione_operazione}
          </span>{" "}
        </div>

        <div className="mb-1">
          <FontAwesomeIcon icon={faCalendarDay} />
          <label style={{ fontWeight: 600 }} className="ml-1">
            Data decorrenza:
          </label>{" "}
          {data}
        </div>
        <div className="mb-1">
          <FontAwesomeIcon icon={faCalendarTimes} />
          <label style={{ fontWeight: 600 }} className="ml-1">
            Data scadenza:
          </label>{" "}
          {moment(props.finanziamento.data_scadenza_finanziamento).format(
            "DD/MM/YYYY"
          )}
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faMoneyBill} className="mr-1" />
          <span style={{ fontWeight: 600 }}>Rata mensile:</span>{" "}
          {props.finanziamento.rata_stipulata} €
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faCalendarTimes} className="mr-1" />
          <span style={{ fontWeight: 600 }}>Durata:</span>{" "}
          {props.finanziamento.durata_mesi} mesi
        </div>
      </div>
    </div>
  );
};

export default OpportunitaItem;
