import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Login from "./login/pages/Login";

import MainNavigation from "./shared/components/Navigation/MainNavigation";
//Contatti
import Contatti from "./contatti/pages/PaginaContatti";
import DettagliContatto from "./contatti/pages/DettagliContatto";
import Amministrazioni from "./amministrazione/pages/PaginaAmministrazioni";
import DettagliAmministrazione from "./amministrazione/pages/DettagliAmministrazione";
import Istituti from "./istituto_finanziario/pages/PaginaIstituti";
import DettagliIstituto from "./istituto_finanziario/pages/DettagliIstituto";
import Collaboratori from "./collaboratore/pages/PaginaCollaboratori";
import DettagliCollaboratore from "./collaboratore/pages/DettagliCollaboratore";
import Opportunita from "./opportunita/pages/PaginaOpportunita";
import DettagliOpportunita from "./opportunita/pages/DettagliOpportunita";
import Produzione from "./produzione/pages/PaginaProduzione";
import Finanziamenti from "./finanziamenti/pages/PaginaFinanziamenti";
import Impostazioni from "./impostazioni/pages/PaginaImpostazioni";
import authService from "./services/authService";
import { Notifications } from "react-push-notification";
import { requestFirebaseNotificationPermission } from "./services/firebaseInit";
import { useEffect } from "react";

function App() {
  const user = authService.getCurrentRuolo();

  useEffect(() => {
    if(authService.getCurrentToken()) {
      requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        // eslint-disable-next-line no-console
        console.log(firebaseToken);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    
  }, []);

  let routes;

  if (user) {
    if (user === "amministratore") {
      routes = (
        <Switch>
          <Route path="/contatti" exact>
            <Contatti />
          </Route>
          <Route path="/dettagliContatto/:id_contatto" exact>
            <DettagliContatto />
          </Route>
          <Route path="/amministrazioni" exact>
            <Amministrazioni />
          </Route>
          <Route path="/dettagliAmministrazione/:ref_amministrazione" exact>
            <DettagliAmministrazione />
          </Route>
          <Route path="/istituti" exact>
            <Istituti />
          </Route>
          <Route path="/dettagliIstituto/:ref_istituto" exact>
            <DettagliIstituto />
          </Route>
          <Route path="/collaboratori" exact>
            <Collaboratori />
          </Route>
          <Route path="/dettagliCollaboratore/:ref_collaboratore" exact>
            <DettagliCollaboratore />
          </Route>
          <Route path="/opportunita" exact>
            <Opportunita />
          </Route>
          <Route path="/dettagliOpportunita/:id_contatto/:id_opportunita" exact>
            <DettagliOpportunita />
          </Route>
          <Route path="/produzione" exact>
            <Produzione />
          </Route>
          <Route path="/finanziamenti" exact>
            <Finanziamenti />
          </Route>
          <Route path="/impostazioni" exact>
            <Impostazioni />
          </Route>
          <Redirect to="/contatti" />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <Route path="/contatti" exact>
            <Contatti />
          </Route>
          <Route path="/dettagliContatto/:id_contatto" exact>
            <DettagliContatto />
          </Route>
          <Route path="/amministrazioni" exact>
            <Amministrazioni />
          </Route>
          <Route path="/dettagliAmministrazione/:ref_amministrazione" exact>
            <DettagliAmministrazione />
          </Route>
          <Route path="/istituti" exact>
            <Istituti />
          </Route>
          <Route path="/dettagliIstituto/:ref_istituto" exact>
            <DettagliIstituto />
          </Route>
          <Route path="/opportunitaCollaboratore" exact>
            <Opportunita />
          </Route>
          <Route path="/dettagliOpportunita/:id_contatto/:id_opportunita" exact>
            <DettagliOpportunita />
          </Route>
          <Route path="/impostazioni" exact>
            <Impostazioni />
          </Route>
          <Redirect to="/opportunitaCollaboratore" />
        </Switch>
      );
    }
  } else {
    routes = (
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }
  return (
    <Router>
      <Notifications />
      <MainNavigation />
      <main>{routes}</main>
    </Router>
  );
}

export default App;
