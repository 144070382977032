import {
  faCity,
  faEnvelope,
  faLocationArrow,
  faPhone,
  faSearchPlus,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";

const IstitutiItem = (props) => {
  const history = useHistory();
  return (
    <div
      className="card shadow col-sm-9 mx-auto mt-4"
      style={{ border: "none", borderRadius: "10px" }}
    >
      <div
        className="d-flex justify-content-between mb-3 mt-2"
        style={{ fontSize: "105%" }}
      >
        <div>
          <FontAwesomeIcon icon={faUniversity} />{" "}
          <span style={{ fontWeight: 700, fontSize: "110%" }} className="ml-1">
            {props.denominazione}
          </span>{" "}
        </div>
        <div>
          <FontAwesomeIcon
            role="button"
            icon={faSearchPlus}
            size="lg"
            onClick={() =>
              history.push(`/dettagliIstituto/${props.id_istituto}`)
            }
          />
        </div>
      </div>
      {props.citta && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faCity} />{" "}
          <span className="ml-1">
            {props.citta}, {props.provincia}, {props.cap}
          </span>
        </div>
      )}
      {props.indirizzo && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faLocationArrow} />{" "}
          <span className="ml-1">{props.indirizzo}</span>
        </div>
      )}
      {props.email && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faEnvelope} />{" "}
          <span className="ml-1">{props.email}</span>
        </div>
      )}
      <div className="mb-2">
        <FontAwesomeIcon icon={faPhone} />{" "}
        {props.recapito_1 && (
          <span className="ml-1 mr-2">{props.recapito_1}</span>
        )}
        {props.recapito_2 && <span className="ml-1">{props.recapito_2}</span>}
      </div>
    </div>
  );
};

export default IstitutiItem;
