import React, { useState } from "react";
import moment from "moment";
import {
  faCalendarAlt,
  faClipboard,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

const NoteItem = (props) => {
  const [visible, setVisibile] = useState(false);
  const data = moment(props.data).format("DD/MM/YYYY HH:mm");

  const handleClose = () => {
    setVisibile(false);
  };

  return (
    <React.Fragment>
      {visible && (
        <props.ModificaNota
          id_nota={props.id_nota}
          testo={props.testo}
          handleClose={handleClose}
        />
      )}
      <div
        className="card shadow col-sm-9 mx-auto mt-4"
        style={{ border: "none", borderRadius: "10px" }}
      >
        <div className="mb-3 mt-2">
          <FontAwesomeIcon icon={faCalendarAlt} />
          <span style={{ fontWeight: 600 }} className="ml-1">
            Data:
          </span>{" "}
          {data}
        </div>
        <div className="mb-3">
          <div className="col-12 p-0">
            <FontAwesomeIcon icon={faClipboard} />
            <label style={{ fontWeight: 600 }} className="ml-1 ">
              Testo:
            </label>
          </div>
          <div className="col-12 p-0">
            <Form>
              <TextareaAutosize
                id="testo"
                placeholder="Testo nota"
                name="testo"
                type="text"
                readOnly
                value={props.testo}
              />
            </Form>
          </div>
        </div>
        <div className="mb-2">
          <label
            role="button"
            onClick={() => props.eliminaNota(props.id_nota)}
            style={{ fontWeight: 600 }}
            className="mr-3"
          >
            <FontAwesomeIcon icon={faTrash} /> Elimina
          </label>
          <label
            role="button"
            style={{ fontWeight: 600 }}
            onClick={() => setVisibile(true)}
          >
            <FontAwesomeIcon icon={faEdit} /> Modifica
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoteItem;
