import React from "react";

import ContactItem from "./ContactItem";

const ContactList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="row mx-0 justify-content-center">
        <div className="col-12">
          <p
            className="col card py-2 px-3 shadow mt-3"
            style={{ fontSize: "14px", borderRadius: "10px" }}
          >
            Al momento non ci sono clienti registrati, aggiungi un nuovo cliente per visualizzarlo qui.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <ul className=" p-0 my-2 mx-3">
        {props.items.sort((a,b) => (a.cognome > b.cognome ? 1 : -1)).map((contatto) => (
          <ContactItem
            key={contatto.id_contatto}
            id_contatto = {contatto.id_contatto}
            nome={contatto.nome}
            cognome={contatto.cognome}
            cellulare={contatto.cellulare_1}
            telefono={contatto.telefono_casa}
            email={contatto.email}
            tipo = {contatto.tipo}
          />
        ))}
      </ul>
      <br></br>
      </React.Fragment>
      
    );
  }
};

export default ContactList;
