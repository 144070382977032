import {
  faBuilding,
  faCity,
  faLocationArrow,
  faPhone,
  faSearchDollar,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";

const AmministrazioniItem = (props) => {
  const history = useHistory();
  return (
    <div
      className="card shadow col-sm-9 mx-auto mt-4"
      style={{ border: "none", borderRadius: "10px" }}
    >
      <div
        className="d-flex justify-content-between mt-2 mb-3"
        style={{ fontSize: "105%" }}
      >
        <div>
          <FontAwesomeIcon icon={faBuilding} />{" "}
          <span style={{ fontWeight: 700, fontSize: "110%" }} className="ml-3">
            {props.amministrazione.denominazione}
          </span>{" "}
        </div>
        <div>
          <FontAwesomeIcon
            role="button"
            icon={faSearchPlus}
            size="lg"
            onClick={() =>
              history.push(
                `/dettagliAmministrazione/${props.amministrazione.id_amministrazione}`
              )
            }
          />
        </div>
      </div>
      <div className="mb-3">
        <FontAwesomeIcon icon={faSearchDollar} />{" "}
        <span className="ml-3">
          {
            props.amministrazione.tipologia_amministrazione
              .descrizione_tipologia
          }
        </span>
      </div>
      {props.amministrazione.citta && (
        <div className="mb-3">
          <FontAwesomeIcon icon={faCity} />
          <span className="ml-3">
          {props.amministrazione.citta}, {props.amministrazione.provincia}, {props.amministrazione.cap}
          </span>
        </div>
      )}
      {props.amministrazione.indirizzo_notifica_cartacea && (
        <div className="row mb-3">
          <div className="col-sm-12 col-lg-6 col-md-6 mb-3 mb-lg-0 mb-md-0">
            <FontAwesomeIcon icon={faLocationArrow} />{" "}
            <span className="ml-3">{props.amministrazione.indirizzo_notifica_cartacea}</span>
          </div>
        </div>
      )}
      {props.amministrazione.telefono_resp_1 && (
        <div className="row mb-3">
          <div className="col-sm-12 col-lg-6 col-md-6 mb-3 mb-lg-0 mb-md-0">
            <FontAwesomeIcon icon={faPhone} /><span style={{fontSize:"85%", fontWeight:"700"}} > 1</span>
            <span className="ml-2">{props.amministrazione.telefono_resp_1}</span>
          </div>
        </div>
      )}
      {props.amministrazione.telefono_resp_2 && (
        <div className="row mb-2">
          <div className="col-sm-12 col-lg-6 col-md-6 mb-3 mb-lg-0 mb-md-0">
            <FontAwesomeIcon icon={faPhone} /><span style={{fontSize:"85%", fontWeight:"700"}} > 2</span>
            <span className="ml-2">{props.amministrazione.telefono_resp_2}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmministrazioniItem;
