import React, {useState } from "react";
import { Dropdown, Form} from "semantic-ui-react";
import { useFormik } from "formik";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useHttpClient } from "../../util/http-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import authService from "../../services/authService";
import lista_province from "../../services/province.json";
import lista_comuni from "../../services/comuni.json";

const tipo = [
  { key: 1, value: "Cliente", text: "Cliente" },
  { key: 2, value: "Lead", text: "Lead" },
];

const clienteSchema = Yup.object().shape({
  nome: Yup.string().required(),
  cognome: Yup.string().required(),
  tipo: Yup.string().required(),
  cellulare_1: Yup.string()
    .matches(/^[0-9+]*$/, "cellulare_1 deve contenere solamente numeri o +"),
  cellulare_2: Yup.string()
    .matches(/^[0-9+]*$/, "cellulare 2 deve contenere solamente numeri o +")
});

const DettagliPersonali = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [dataNascita, setDataNascita] = useState(props.info.data_nascita);

  const formik = useFormik({
    initialValues: {
      cf: props.info.cf,
      nome: props.info.nome,
      cognome: props.info.cognome,
      citta_nascita: props.info.citta_nascita,
      citta_residenza: props.info.citta_residenza,
      indirizzo_residenza: props.info.indirizzo_residenza,
      provincia_residenza: props.info.provincia_residenza,
      cap_residenza: props.info.cap_residenza,
      cellulare_1: props.info.cellulare_1,
      cellulare_2: props.info.cellulare_2,
      telefono_casa: props.info.telefono_casa,
      telefono_ufficio: props.info.telefono_ufficio,
      email: props.info.email,
      tipo: props.info.tipo,
    },
    validationSchema: clienteSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/modificaInfoPersonali/",
          "POST",
          JSON.stringify({
            id_contatto: props.info.id_contatto,
            cf: values.cf,
            nome: values.nome,
            cognome: values.cognome,
            data_nascita: dataNascita,
            citta_nascita: values.citta_nascita,
            citta_residenza: values.citta_residenza,
            provincia_residenza: values.provincia_residenza,
            indirizzo_residenza: values.indirizzo_residenza,
            cap_residenza: values.cap_residenza,
            cellulare_1: values.cellulare_1,
            cellulare_2: values.cellulare_2,
            telefono_casa: values.telefono_casa,
            telefono_ufficio: values.telefono_ufficio,
            email: values.email,
            tipo: values.tipo,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazione completata!",
          text: "Contatto inserito correttamente!",
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err.message,
        });
      }
    },
  });

  const handleChangeProvincia = () => {
    let trovato = 0;

    lista_comuni.forEach((provincia) => {
      provincia.comuni.forEach((comune) => {
        if (
          comune.value.toLocaleLowerCase() ===
          formik.values.citta_residenza.toLocaleLowerCase()
        ) {
          trovato = 1;
          formik.setFieldValue("provincia_residenza", provincia.id);
        }
      });
    });

    if (!trovato) {
      formik.setFieldValue("provincia_residenza", "");
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            style={{ fontWeight: 600 }}
            onClick={formik.handleSubmit}
          >
            <FontAwesomeIcon icon={faSave} /> Salva modifiche
          </label>
        </div>
      </div>
      <div>
        <div style={{ fontSize: "110%" }}>
          <Form>
            <Form.Input
              label="Codice fiscale"
              id="cf"
              value={formik.values.cf}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.cf && formik.touched.cf}
            />
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Nome"
                id="nome"
                value={formik.values.nome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.nome && formik.touched.nome}
              />
              <Form.Input
                required
                label="Cognome"
                id="cognome"
                value={formik.values.cognome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.cognome && formik.touched.cognome}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label style={{ fontWeight: 700 }}>Data di nascita</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <KeyboardDatePicker
                    fullWidth
                    clearable
                    cancelLabel="Cancella"
                    clearLabel="Annulla"
                    name="data"
                    value={dataNascita}
                    onChange={setDataNascita}
                    format={"DD-MM-YYYY"}
                  />
                </MuiPickersUtilsProvider>
              </Form.Field>
              <Form.Input
                label="Città di nascita"
                id="citta_nascita"
                value={formik.values.citta_nascita}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.citta_nascita && formik.touched.citta_nascita
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Provincia</label>
                <Dropdown
                  error={formik.errors.provincia && formik.touched.provincia}
                  placeholder="Provincia"
                  fluid
                  selection
                  search
                  options={lista_province}
                  value={formik.values.provincia_residenza}
                  onChange={(e, data) =>
                    formik.setFieldValue("provincia_residenza", data.value)
                  }
                  onBlur={() => formik.setFieldTouched("provincia")}
                />
              </Form.Field>
              <Form.Input
                label="Città"
                id="citta_residenza"
                value={formik.values.citta_residenza}
                onChange={formik.handleChange}
                onBlur={() => handleChangeProvincia()}
                error={formik.errors.citta && formik.touched.citta}
                placeholder="Citta"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Indirizzo di residenza "
                id="indirizzo_residenza"
                value={formik.values.indirizzo_residenza}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.indirizzo_residenza &&
                  formik.touched.indirizzo_residenza
                }
              />
              <Form.Input
                label="CAP residenza"
                id="cap_residenza"
                value={formik.values.cap_residenza}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.cap_residenza && formik.touched.cap_residenza
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Cellulare 1"
                id="cellulare_1"
                value={formik.values.cellulare_1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.cellulare_1 && formik.touched.cellulare_1}
              />
              <Form.Input
                label="Cellulare 2"
                id="cellulare_2"
                value={formik.values.cellulare_2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.cellulare_2 && formik.touched.cellulare_2}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Telefono casa"
                id="telefono_casa"
                value={formik.values.telefono_casa}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.telefono_casa && formik.touched.telefono_casa
                }
              />
              <Form.Input
                label="Telefono ufficio"
                id="telefono_ufficio"
                value={formik.values.telefono_ufficio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.telefono_ufficio &&
                  formik.touched.telefono_ufficio
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email && formik.touched.email}
              />
              <Form.Field readOnly>
                <label>Tipo</label>
                <Dropdown
                  error={formik.errors.tipo && formik.touched.tipo}
                  fluid
                  selection
                  search
                  disabled={authService.getCurrentRuolo() === "collaboratore"}
                  options={tipo}
                  defaultValue={props.info.tipo}
                  onChange={(e, data) =>
                    formik.setFieldValue("tipo", data.value)
                  }
                  onBlur={() => formik.setFieldTouched("tipo")}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DettagliPersonali;
