import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faClipboard,
  faPollH,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Form, Icon, Label } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

const OpportunitaItem = (props) => {
  const data = moment(props.data).format("DD/MM/YYYY HH:mm");
  return (
    <div
      className="card shadow col-sm-9 mx-auto mt-4"
      style={{ border: "none", borderRadius: "10px" }}
    >
      <div className="mb-3 mt-1">
        <span style={{ fontWeight: 600, fontSize: "130%" }}>
          {props.nome_attivita}
        </span>{" "}
        <Icon name={props.icona_attivita}></Icon>
        {!props.completata ? (
          <Label color="blue" className="float-right">
            Programmata
            <Icon name="checked calendar" className="ml-1 mr-0" />
          </Label>
        ) : null}
      </div>
      <div className="mb-3">
        <FontAwesomeIcon icon={faCalendarDay} />
        <label style={{ fontWeight: 600 }} className="ml-1">
          Data e ora:
        </label>{" "}
        {data}
      </div>
      <div className="mb-3">
        <div className="col-12 p-0">
          <FontAwesomeIcon icon={faClipboard} />
          <label style={{ fontWeight: 600 }} className="ml-1">
            Note:
          </label>
        </div>
        <Form>
          <TextareaAutosize
            id="testo"
            placeholder="Testo nota"
            name="testo"
            type="text"
            readOnly
            value={props.note}
          />
        </Form>
      </div>
      {props.completata ? (
        <div className="mb-3">
          <div className="col-12 p-0">
            <FontAwesomeIcon icon={faPollH} />
            <label style={{ fontWeight: 600 }} className="ml-1">
              Esito:
            </label>
          </div>
          <Form>
            <TextareaAutosize
              id="testo"
              placeholder="Testo nota"
              name="testo"
              type="text"
              readOnly
              value={props.esito}
            />
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default OpportunitaItem;
