import React from "react";
import { Form, Button, Icon, Header } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHttpClient } from "../../util/http-hook";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHistory } from "react-router-dom";

const cambiaPasswordSchema = Yup.object().shape({
  password: Yup.string().required(),
  passwordConferma: Yup.string().required(),
});

const ModificaInformazioni = () => {
  const { sendRequest } = useHttpClient();
  const history = useHistory();
  const token = authService.getCurrentToken();
  const email = authService.getCurrentEmail();


  const passwordData = useFormik({
    initialValues: {
      password: "",
      passwordConferma: "",
    },
    validationSchema: cambiaPasswordSchema,
    onSubmit: async (values) => {
      if (values.password === values.passwordConferma) {
   
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL + "/autenticazione/modificaPassword",
            "POST",
            JSON.stringify({
              email: email,
              password: values.password,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Password modificata correttamente.",
          }).then(() => history.go(0));
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error.message,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Le due password inserite non coincidono!",
        });
      }
    },
  });

  return (
    <React.Fragment>
      <Header as="h3" className="text-center">
        Modifica Password
      </Header>

      <Form size="medium">
        <Form.Input
          label="Password:"
          id="password"
          type="password"
          value={passwordData.values.password}
          onChange={passwordData.handleChange}
          onBlur={passwordData.handleBlur}
          error={passwordData.errors.password && passwordData.touched.password}
        />
        <Form.Input
          label="Conferma Password:"
          id="passwordConferma"
          type="password"
          value={passwordData.values.passwordConferma}
          onChange={passwordData.handleChange}
          onBlur={passwordData.handleBlur}
          error={
            passwordData.errors.passwordConferma &&
            passwordData.touched.passwordConferma
          }
        />
        <div>
          <Button
            type="submit"
            color="blue"
            onClick={passwordData.handleSubmit}
          >
            <Icon name="edit" /> Modifica Password
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default ModificaInformazioni;
