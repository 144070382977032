import React, { useState } from "react";
import { faClipboard, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Button, Form, TextArea, Modal} from "semantic-ui-react";

import NoteItem from "../../../shared/components/NoteItem";
import Swal from "sweetalert2";
import authService from "../../../services/authService";

import * as Yup from "yup";
import { useHttpClient } from "../../../util/http-hook";
import { useHistory } from "react-router-dom";
import ModificaNota from './ModificaNotaAmministrazione'

const notaAmministrazioneSchema = Yup.object().shape({
  testo: Yup.string().required("Richiesto"),
});

const NoteList = (props) => {
  const [open, setOpen] = useState(false);
  const token = authService.getCurrentToken();
  const { sendRequest } = useHttpClient();
  const history = useHistory();

  const eliminaNota = (id_nota) => {
    Swal.fire({
      icon: "question",
      title: "Sei sicuro di voler cancellare la nota?",
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonText: "Conferma",
    }).then(async (response) => {
      if (response.isConfirmed) {
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL + `/amministrazioni/eliminaNota/${id_nota}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Nota eliminata correttamente",
          }).then(() => {
            history.go(0);
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      testo: "",
    },
    validationSchema: notaAmministrazioneSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/amministrazioni/aggiungiNota",
          "POST",
          JSON.stringify({
            ref_amministrazione: props.ref_amministrazione,
            testo: encodeURIComponent(values.testo) ,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Nota caricata correttamente",
        }).then(() => {
          props.note.push(responseData);
          setOpen(false);
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });

  const handleClose = async function () {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        onClose={handleClose}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Aggiungi nuova nota <FontAwesomeIcon icon={faClipboard} />{" "}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description className="text-center">
            <Form>
              <TextArea
                id="testo"
                placeholder="Testo nota"
                name="testo"
                type="text"
                error={formik.errors.testo && formik.touched.testo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.testo}
              />
            </Form>
          </Modal.Description>
          <Modal.Actions>
            <Button
              positive
              className="float-right mt-md-2"
              onClick={formik.handleSubmit}
            >
              Aggiungi
            </Button>
          </Modal.Actions>
        </Modal.Content>
      </Modal>
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            style={{ fontWeight: 600 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Nuova nota
          </label>
        </div>
      </div>
      {props.note.length === 0 ? (
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            <p
              className="col card py-2 px-3 shadow mt-3"
              style={{ fontSize: "14px", borderRadius: "10px" }}
            >
              Al momento non ci sono note per questa amministrazione. Aggiungine
              una tramite l'apposito tasto.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <ul className=" p-0 my-2 mx-3">
            {props.note
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .map((nota) => (
                <NoteItem
                  key={nota.id_nota}
                  id_nota={nota.id_nota}
                  data={nota.createdAt}
                  testo={nota.testo_nota ? decodeURIComponent(nota.testo_nota) : ""}
                  eliminaNota={eliminaNota}
                  ModificaNota={ModificaNota}
                />
              ))}
          </ul>
          <br></br>
        </div>
      )}
    </React.Fragment>
  );
};

export default NoteList;
