import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Form, Label, TextArea } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHttpClient } from "../../util/http-hook";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import authService from "../../services/authService";

moment.locale("it");

const opportunitaSchema = Yup.object().shape({
  ref_contatto: Yup.string().required("Richiesto"),
  ref_attivita: Yup.number().required("Richiesto"),
  data_opportunita: Yup.date().required("Richiesto"),
});

const InformazioniOpportunita = (props) => {
  const history = useHistory();
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [contatti, setContatti] = useState();
  const [attivita, setAttivita] = useState();
  const [operazioni, setOperazioni] = useState();
  const [selectedDate, setDate] = useState(props.opportunita.data_opportunita);
  const [dataProssimaOpportunita, setDataProssimaOpportunita] = useState(null);

  const handleDateChange = (data) => {
    setDate(data);
    informazioni.setFieldValue("data_opportunita", data);
  };

  useEffect(() => {
    const ottieniOperazioni = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/operazioni/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const operazioni_response = [];
        responseData.forEach((operazione) => {
          if (operazione.id_operazione !== 4) {
            operazioni_response.push({
              key: operazione.id_operazione,
              value: operazione.id_operazione,
              text: operazione.descrizione_operazione,
            });
          }
        });
        setOperazioni(operazioni_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    const ottieniContatti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const contatti_response = [];

        responseData.listaContatti.forEach((contatto) => {
          contatti_response.push({
            key: contatto.id_contatto,
            value: contatto.id_contatto,
            text: contatto.cognome + " " + contatto.nome,
          });
        });
        setContatti(contatti_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    const ottieniAttivita = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/attivita/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const attivita_response = [];
        responseData.forEach((attivita) => {
          attivita_response.push({
            key: attivita.id_attivita,
            value: attivita.id_attivita,
            text: attivita.descrizione_attivita,
            icon: attivita.icona,
          });
        });
        setAttivita(attivita_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    ottieniOperazioni();
    ottieniAttivita();
    ottieniContatti();
  }, [sendRequest, token]);

  const informazioni = useFormik({
    initialValues: {
      ref_contatto: props.opportunita.ref_contatto,
      ref_attivita: props.opportunita.ref_attivita,
      note_opportunita: props.opportunita.note_opportunita
        ? decodeURIComponent(props.opportunita.note_opportunita)
        : "",
      esito_opportunita: props.opportunita.esito_opportunita
        ? decodeURIComponent(props.opportunita.esito_opportunita)
        : "",
      data_opportunita: props.opportunita.data_opportunita,
      ref_prossima_attivita: "",
      note_prossima_opportunita: "",
      ref_prossima_operazione: "",
      completata: props.opportunita.completata,
    },
    validationSchema: opportunitaSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/opportunita/modificaOpportunita/${props.opportunita.id_opportunita}`,
          "POST",
          JSON.stringify({
            ref_contatto: values.ref_contatto,
            ref_attivita: values.ref_attivita,
            note_opportunita: values.note_opportunita
              ? encodeURIComponent(values.note_opportunita)
              : "",
            esito_opportunita: values.esito_opportunita
              ? encodeURIComponent(values.esito_opportunita)
              : "",
            data_opportunita: values.data_opportunita,
            data_prossima_opportunita: dataProssimaOpportunita,
            note_prossima_opportunita: encodeURIComponent(
              values.note_prossima_opportunita
            ),
            ref_prossima_attivita: values.ref_prossima_attivita,
            ref_prossima_operazione: values.ref_prossima_operazione,
            completata: values.completata,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazione completata correttamente",
          text: responseData,
        }).then(() => history.go(0));
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        }).then(() => history.go(0));
      }
    },
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            className=""
            style={{ fontWeight: 600 }}
            onClick={informazioni.handleSubmit}
          >
            <FontAwesomeIcon icon={faSave} /> Salva modifiche
          </label>
        </div>
      </div>
      <div>
        <div style={{ fontSize: "110%" }}>
          <Form>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Contatto</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_contatto &&
                    informazioni.touched.ref_contatto
                  }
                  placeholder="Contatto"
                  fluid
                  search
                  selection
                  value={informazioni.values.ref_contatto}
                  options={contatti ? contatti : []}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("ref_contatto", data.value)
                  }
                  onBlur={() => informazioni.setFieldTouched("ref_contatto")}
                  readOnly
                />
              </Form.Field>
              <Form.Field required>
                <label>Attività</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_attivita &&
                    informazioni.touched.ref_attivita
                  }
                  placeholder="Attività"
                  fluid
                  selection
                  value={informazioni.values.ref_attivita}
                  options={attivita ? attivita : []}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("ref_attivita", data.value)
                  }
                  onBlur={() => informazioni.setFieldTouched("ref_attivita")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field className="mb-4" required>
              <label style={{ fontWeight: 700 }}>Data decorrenza</label>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale={"it"}
              >
                <DateTimePicker
                  fullWidth
                  size="medium"
                  ampm={false}
                  clearable
                  cancelLabel="Cancella"
                  error={
                    informazioni.errors.data_opportunita &&
                    informazioni.touched.data_opportunita
                  }
                  clearLabel="Annulla"
                  id="data_opportunita"
                  value={selectedDate}
                  onBlur={informazioni.handleBlur}
                  onChange={(date) => handleDateChange(date)}
                  format="DD/MM/yyyy HH:mm"
                />
              </MuiPickersUtilsProvider>
            </Form.Field>

            <Form.Field>
              <label>Note</label>
              <TextArea
                id="note_opportunita"
                value={informazioni.values.note_opportunita}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Esito</label>
              <TextArea
                id="esito_opportunita"
                value={informazioni.values.esito_opportunita}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Field>
            <div className="row ml-1">
              <label style={{ fontWeight: "700" }} className="mr-2">
                Completato
              </label>
              <Checkbox
                toggle
                defaultChecked={Boolean(props.opportunita.completata)}
                onChange={(e, data) =>
                  informazioni.setFieldValue("completata", data.checked)
                }
              />
            </div>

            <Label size="large" className="my-3">
              Programma nuova opportunità
            </Label>
            <Form.Group widths="equal">
              <Form.Field className="mb-2">
                <label style={{ fontWeight: 700 }}>
                  Data prossima opportunita
                </label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale={"it"}
                >
                  <DateTimePicker
                    fullWidth
                    size="medium"
                    ampm={false}
                    clearable
                    cancelLabel="Cancella"
                    clearLabel="Annulla"
                    id="data_prossima_opportunita"
                    value={
                      dataProssimaOpportunita
                        ? moment(dataProssimaOpportunita)
                        : null
                    }
                    onChange={setDataProssimaOpportunita}
                    format="DD/MM/yyyy HH:mm"
                  />
                </MuiPickersUtilsProvider>
              </Form.Field>
              <Form.Field>
                <label>Prossima attività</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_prossima_attivita &&
                    informazioni.touched.ref_prossima_attivita
                  }
                  placeholder="Attività"
                  fluid
                  selection
                  value={informazioni.values.ref_prossima_attivita}
                  options={attivita ? attivita : []}
                  onChange={(e, data) =>
                    informazioni.setFieldValue(
                      "ref_prossima_attivita",
                      data.value
                    )
                  }
                  onBlur={() =>
                    informazioni.setFieldTouched("ref_prossima_attivita")
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Finanziamento</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_prossima_operazione &&
                    informazioni.touched.ref_prossima_operazione
                  }
                  placeholder="Finanziamento"
                  fluid
                  selection
                  value={informazioni.values.ref_prossima_operazione}
                  options={operazioni}
                  onChange={(e, data) =>
                    informazioni.setFieldValue(
                      "ref_prossima_operazione",
                      data.value
                    )
                  }
                  onBlur={() =>
                    informazioni.setFieldTouched("ref_prossima_operazione")
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>Note prossima opportunita</label>
              <TextArea
                id="note_prossima_opportunita"
                value={informazioni.values.note_prossima_opportunita}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Field>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InformazioniOpportunita;
