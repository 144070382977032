import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React from "react";
import { Button, Dropdown, Form, Icon, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";

const utenteSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
  ruolo: Yup.string().required(),
});

const ruoli = [
  { key: 1, text: "Collaboratore", value: "collaboratore" },
  { key: 2, text: "Amministratore", value: "amministratore" },
];

const AggiungiUtente = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();



  const formUtente = useFormik({
    initialValues: {
      email: "",
      password: "",
      confermaPassword: "",
      ruolo: "",
    },
    validationSchema: utenteSchema,
    onSubmit: async (values) => {
      if (values.password === values.confermaPassword) {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BASE_URL + "/utenti/aggiungiUtente",
            "POST",
            JSON.stringify({
              email: values.email,
              password: values.password,
              ruolo: values.ruolo,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Utente aggiunto correttamente",
          }).then(() => {
            props.aggiungiUtente(responseData);
            props.handleClose();
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Le due password non coincidono.",
        });
      }
    },
  });

  return (
    <Modal
      onClose={() => props.handleClose()}
      open={true}
      style={{ position: "relative", height: "auto" }}
    >
      <Modal.Header className="text-center">
        Aggiungi utente <FontAwesomeIcon icon={faUser} />{" "}
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form unstackable>
            <Form.Field>
              <label>Ruolo</label>
              <Dropdown
                selection
                placeholder="Ruolo"
                value={formUtente.values.ruolo}
                fluid
                onChange={(e, data) =>
                  formUtente.setFieldValue("ruolo", data.value)
                }
                onBlur={() => formUtente.setFieldTouched("ruolo")}
                error={formUtente.errors.ruolo && formUtente.touched.ruolo}
                options={ruoli}
              />
            </Form.Field>
            <Form.Input
              id="email"
              label="Email"
              value={formUtente.values.email}
              onChange={formUtente.handleChange}
              onBlur={formUtente.handleBlur}
              error={formUtente.errors.email && formUtente.touched.email}
            />
            <Form.Input
              id="password"
              label="Password"
              type="password"
              value={formUtente.values.password}
              onChange={formUtente.handleChange}
              onBlur={formUtente.handleBlur}
              error={formUtente.errors.password && formUtente.touched.password}
            />
            <Form.Input
              id="confermaPassword"
              label="Conferma password"
              type="password"
              value={formUtente.values.confermaPassword}
              onChange={formUtente.handleChange}
              onBlur={formUtente.handleBlur}
              error={
                formUtente.errors.confermaPassword &&
                formUtente.touched.confermaPassword
              }
            />
          </Form>
        </Modal.Description>
        <Modal.Actions>
          <Button
            className="mt-3 mt-md-3"
            type="submit"
            floated="right"
            positive
            icon
            onClick={formUtente.handleSubmit}
          >
            <Icon name="add circle" /> Aggiungi utente
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default AggiungiUtente;
