import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React from "react";
import { Form } from "semantic-ui-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";

const collaboratoriSchema = Yup.object().shape({
  cognome: Yup.string().required("Richiesto"),
  cellulare_1: Yup.string().required("Richiesto"),
});

const AggiungiCollaboratore = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  const collaboratore = useFormik({
    initialValues: {
      nome: props.info.nome,
      cognome: props.info.cognome,
      cellulare_1: props.info.cellulare_1,
      cellulare_2: props.info.cellulare_2,
      telefono_casa: props.info.telefono_casa,
      telefono_ufficio: props.info.telefono_ufficioe,
      email: props.info.email,
      partita_iva: props.info.partita_iva,
    },
    validationSchema: collaboratoriSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL +
            "/collaboratori/modificaCollaboratore",
          "POST",
          JSON.stringify({
            id_collaboratore: props.info.id_collaboratore,
            nome: values.nome,
            cognome: values.cognome,
            cellulare_1: values.cellulare_1,
            cellulare_2: values.cellulare_2,
            telefono_casa: values.telefono_casa,
            telefono_ufficio: values.telefono_ufficio,
            email: values.email,
            partita_iva: values.partita_iva,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Collaboratore aggiunto correttamente",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            style={{ fontWeight: 600 }}
            onClick={collaboratore.handleSubmit}
          >
            <FontAwesomeIcon icon={faSave} /> Salva modifiche
          </label>
        </div>
      </div>
      <div style={{ fontSize: "110%" }}>
        <Form unstackable>
          <Form.Group widths="equal">
            <Form.Input
              label="Nome"
              id="nome"
              className="mb-3 mb-md-0"
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.nome}
            />
            <Form.Input
              required
              label="Cognome"
              id="cognome"
              className="mb-3 mb-md-0"
              error={
                collaboratore.errors.cognome && collaboratore.touched.cognome
              }
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.cognome}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              required
              label="Cellulare 1"
              id="cellulare_1"
              className="mb-3 mb-md-0"
              error={
                collaboratore.errors.cellulare_1 &&
                collaboratore.touched.cellulare_1
              }
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.cellulare_1}
            />
            <Form.Input
              label="Cellulare 2"
              id="cellulare_2"
              className="mb-3 mb-md-0"
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.cellulare_2}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Telefono casa"
              id="telefono_casa"
              className="mb-3 mb-md-0"
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.telefono_casa}
            />
            <Form.Input
              label="Telefono ufficio"
              id="telefono_ufficio"
              className="mb-3 mb-md-0"
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.telefono_ufficio}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Email"
              id="email"
              className="mb-3 mb-md-0"
              error={collaboratore.errors.email && collaboratore.touched.email}
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.email}
            />
            <Form.Input
              label="Partita IVA"
              id="partita_iva"
              onChange={collaboratore.handleChange}
              onBlur={collaboratore.handleBlur}
              value={collaboratore.values.partita_iva}
            />
          </Form.Group>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default AggiungiCollaboratore;
