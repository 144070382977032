import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBuilding,
  faChartLine,
  faCog,
  faHandHoldingUsd,
  faLightbulb,
  faSignOutAlt,
  faUniversity,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Label } from "semantic-ui-react";

import "./NavLinks.css";
import authService from "../../../services/authService";
import Swal from "sweetalert2";
import { useHttpClient } from "../../../util/http-hook";

const NavLinks = (props) => {
  const { sendRequest } = useHttpClient();
  const ruolo = authService.getCurrentRuolo();
  const token = authService.getCurrentToken();
  const [notifiche, setNotifiche] = useState(0);
  const history = useHistory();
  const handleLogOut = () => {
    authService.logout();
    history.push("/");
    history.go(0);
  };

  useEffect(() => {
    const ottieniOpportunitaNonCompletate = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/opportunita/nonCompletate",
          "POST",
          JSON.stringify({
            ruolo: authService.getCurrentRuolo(),
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        setNotifiche(responseData);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    ottieniOpportunitaNonCompletate();
  }, [sendRequest, token]);
  return (
    <div>
      {ruolo === "amministratore" && (
        <div>
          <div className="burger-navlinks">
            <Hamburger
              toggled={true}
              onToggle={props.onCloseDrower}
              color="#385898"
            />
          </div>
          <ul className="nav-links">
            <li onClick={props.onCloseDrower}>
              <NavLink to="/contatti">
                <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
                Contatti
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/amministrazioni">
                <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                Amministrazioni
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/istituti">
                <FontAwesomeIcon icon={faUniversity} className="mr-2" />
                Istituti Finanziari
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/finanziamenti">
                <FontAwesomeIcon icon={faHandHoldingUsd} className="mr-2" />
                Finanziamenti
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/collaboratori">
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                Collaboratori
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/produzione">
                <FontAwesomeIcon icon={faChartLine} className="mr-2" />
                Produzione
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/opportunita">
                <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                Opportunità{" "}
                {notifiche >= 1 && (
                  <Label className="ml-1" circular color="red">
                    {notifiche}
                  </Label>
                )}
              </NavLink>
            </li>
            <li onClick={props.onCloseDrower}>
              <NavLink to="/impostazioni">
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                Impostazioni
              </NavLink>
            </li>
            <li>
              <label role="button" onClick={() => handleLogOut()}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                Log out
              </label>
            </li>
          </ul>
        </div>
      )}
      {ruolo === "collaboratore" && (
        <ul className="nav-links">
          <li onClick={props.onCloseDrower}>
            <NavLink to="/contatti">
              <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
              Lead
            </NavLink>
          </li>
          <li onClick={props.onCloseDrower}>
            <NavLink to="/amministrazioni">
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              Amministrazioni
            </NavLink>
          </li>
          <li onClick={props.onCloseDrower}>
            <NavLink to="/istituti">
              <FontAwesomeIcon icon={faUniversity} className="mr-2" />
              Istituti Finanziari
            </NavLink>
          </li>
          <li onClick={props.onCloseDrower}>
            <NavLink to="/opportunita">
              <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
              Opportunità{" "}
              {notifiche >= 1 && (
                <Label className="ml-1" circular color="red">
                  {notifiche}
                </Label>
              )}
            </NavLink>
          </li>
          <li onClick={props.onCloseDrower}>
            <NavLink to="/impostazioni" exact>
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              Impostazioni
            </NavLink>
          </li>
          <li>
            <label role="button" onClick={() => handleLogOut()}>
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Log out
            </label>
          </li>
        </ul>
      )}
    </div>
  );
};

export default NavLinks;
