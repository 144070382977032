import React from "react";

import FianziamentiItem from "../../../contatti/components/DettagliFinanziamenti/FinanziamentiItem";
const FinanziamentiList = (props) => {
  if (props.finanziamenti.length === 0) {
    return (
      <React.Fragment>
        <div>
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento questo collaboratore non ha siglato nessun
                finanziamento.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ul className=" p-0 my-2 mx-3">
          {props.finanziamenti.sort((a,b) => (a.data_decorrenza < b.data_decorrenza ? 1: -1)).map((finanziamento) => (
            <FianziamentiItem
              finanziamento={finanziamento}
              key={finanziamento.id_finanziamento}
              id_finanziamento={finanziamento.id_finanziamento}
              data_decorrenza={finanziamento.data_decorrenza}
              data_scadenza={finanziamento.data_scadenza_finanziamento}
              tipologia={finanziamento.operazione.descrizione_operazione}
              proprietario={finanziamento.tipologia}
              cognome_collaboratore={finanziamento.collaboratore.cognome}
              nome_collaboratore={finanziamento.collaboratore.nome}
              nome_cliente={finanziamento.contatto.nome}
              cognome_cliente={finanziamento.contatto.cognome}
            />
          ))}
        </ul>
        <br></br>
      </React.Fragment>
    );
  }
};

export default FinanziamentiList;
