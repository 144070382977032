import React, {useState } from "react";
import { faLightbulb, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal } from "semantic-ui-react";

import AggiungiOpportunitaContatto from "../AggiungiOpportunitaContatto";

import OpportunitaItem from "./OpportunitaContattoItem";

const OpportunitaList = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = async function () {
    setOpen(false);
  };

  const aggiungiOpportunita = (opportunita) => {
    props.info.push(opportunita);
  };

  return (
    <React.Fragment>
      <Modal
        onClose={handleClose}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Aggiungi nuova opportunità <FontAwesomeIcon icon={faLightbulb} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <AggiungiOpportunitaContatto
              handleClose={handleClose}
              aggiungiOpportunita={aggiungiOpportunita}
              id_contatto={props.id_contatto}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div>
        <div className="row">
          <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
            <label
              role="button"
              style={{ fontWeight: 600 }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Nuova opportunita
            </label>
          </div>
        </div>
        {props.info.length === 0 ? (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non ci sono opportunita per questo contatto.
                Aggiungine una tramite l'apposito tasto.
              </p>
            </div>
          </div>
        ) : (
          <ul className=" p-0 my-3 mx-3">
            {props.info
              .sort((a, b) =>
                a.data_opportunita < b.data_opportunita ? 1 : -1
              )
              .map((opportunita) => (
                <OpportunitaItem
                  key={opportunita.id_opportunita}
                  data={opportunita.data_opportunita}
                  nome_attivita={opportunita.attivitum.descrizione_attivita}
                  icona_attivita={opportunita.attivitum.icona}
                  completata={opportunita.completata}
                  note={opportunita.note_opportunita ? decodeURIComponent(opportunita.note_opportunita) : ""}
                  esito={opportunita.esito_opportunita ? decodeURIComponent(opportunita.esito_opportunita) : "" }
                />
              ))}
          </ul>
        )}
      </div>
    </React.Fragment>
  );
};

export default OpportunitaList;
