import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Dropdown, Form, Button, Modal } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";

import { useHttpClient } from "../../util/http-hook";
import authService from "../../services/authService";
const durata = [
  {
    key: 24,
    text: 24 + " mesi",
    value: 24,
  },
  {
    key: 36,
    text: 36 + " mesi",
    value: 36,
  },
  {
    key: 48,
    text: 48 + " mesi",
    value: 48,
  },
  {
    key: 60,
    text: 60 + " mesi",
    value: 60,
  },
  {
    key: 72,
    text: 72 + " mesi",
    value: 72,
  },
  {
    key: 84,
    text: 84 + " mesi",
    value: 84,
  },
  {
    key: 96,
    text: 96 + " mesi",
    value: 96,
  },
  {
    key: 108,
    text: 108 + " mesi",
    value: 108,
  },
  {
    key: 120,
    text: 120 + " mesi",
    value: 120,
  },
];

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />;
};

const finanziamentiEssereSchema = Yup.object().shape({
  ref_operazione: Yup.number().required(),
  rata_stipulata: Yup.number().required("Richiesto"),
  durata_mesi: Yup.number().required(),
  ref_societa_estinta: Yup.number().required("Richiesto"),
});

const AggiungiFinanziamentoEssere = (props) => {
  const [operazioni, setOperazioni] = useState();
  const [istituti, setIstituti] = useState();
  const [dataDecorrenzaEssere, setDataDecorrenzaEssere] = useState(new Date());
  const { sendRequest } = useHttpClient();
  const [dataScadenza, setDataScadenza] = useState(null);
  const token = authService.getCurrentToken();

  const handleMesi = (value) => {
    formikFinanziamentoEssere.setFieldValue("durata_mesi", value);
    const data_scadenza = moment(dataDecorrenzaEssere).add(value - 1, "M");
    setDataScadenza(data_scadenza);
  };

  const handleDataDecorrenza = (data) => {
    setDataDecorrenzaEssere(data);
    const data_scadenza = moment(data).add(
      formikFinanziamentoEssere.values.durata_mesi - 1,
      "M"
    );

    setDataScadenza(data_scadenza);
  };

  const formikFinanziamentoEssere = useFormik({
    initialValues: {
      ref_operazione: "",
      descrizione_operazione: "",
      rata_stipulata: "",
      durata_mesi: "",
      ref_societa_estinta: "",
    },
    validationSchema: finanziamentiEssereSchema,
    onSubmit: (values) => {
      props.aggiungiFinanziamento({
        ref_operazione: values.ref_operazione,
        descrizione_operazione: values.descrizione_operazione,
        ref_societa_estinta: values.ref_societa_estinta,
        rata_stipulata: values.rata_stipulata,
        durata_mesi: values.durata_mesi,
        data_decorrenza: dataDecorrenzaEssere,
        data_scadenza_finanziamento: dataScadenza,
      });
      props.handleClose();
    },
  });
  useEffect(() => {
    const ottieniOperazioni = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/operazioni/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const operazioni_response = [];
        responseData.forEach((operazione) => {
          operazioni_response.push({
            key: operazione.id_operazione,
            value: operazione.id_operazione,
            text: operazione.descrizione_operazione,
          });
        });
        setOperazioni(operazioni_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    const ottieniIstituti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/istituti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const istituti_response = [];
        responseData.forEach((istituto) => {
          istituti_response.push({
            key: istituto.id_istituto,
            value: istituto.id_istituto,
            text: istituto.denominazione,
          });
        });
        setIstituti(istituti_response);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniIstituti();
    ottieniOperazioni();
  }, [sendRequest, token]);

  const handleChange = (e, data) => {
    e.persist();
    formikFinanziamentoEssere.setFieldValue("ref_operazione", data.value);
    formikFinanziamentoEssere.setFieldValue(
      "descrizione_operazione",
      e.target.textContent
    );
  };

  return (
    <Modal
      onClose={() => props.handleClose()}
      open={true}
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "black",
        name: "close",
      }}
      style={{ position: "relative", height: "auto" }}
      closeOnDimmerClick={false}
    >
      <Modal.Header className="text-center">
        Aggiungi finanziamento in essere{" "}
        <FontAwesomeIcon icon={faList} className="ml-2" />
      </Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Società</label>
              <Dropdown
                error={
                  formikFinanziamentoEssere.errors.ref_societa_estinta &&
                  formikFinanziamentoEssere.touched.ref_societa_estinta
                }
                placeholder="Società"
                fluid
                selection
                search
                options={istituti}
                onChange={(e, data) =>
                  formikFinanziamentoEssere.setFieldValue(
                    "ref_societa_estinta",
                    data.value
                  )
                }
                onBlur={() =>
                  formikFinanziamentoEssere.setFieldTouched("ref_societa_estinta")
                }
              />
            </Form.Field>
            <Form.Field required>
              <label>Operazione</label>
              <Dropdown
                error={
                  formikFinanziamentoEssere.errors.ref_operazione &&
                  formikFinanziamentoEssere.touched.ref_operazione
                }
                placeholder="Operazione"
                fluid
                selection
                search
                options={operazioni}
                onChange={(e, data) => handleChange(e, data)}
                onBlur={() =>
                  formikFinanziamentoEssere.setFieldTouched("ref_operazione")
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
              <Form.Input
                label="Rata finanziamento"
                icon="euro"
                id="rata_stipulata"
                value={formikFinanziamentoEssere.values.rata_stipulata}
                onChange={formikFinanziamentoEssere.handleChange}
                onBlur={formikFinanziamentoEssere.handleBlur}
                error={
                  formikFinanziamentoEssere.errors.rata_stipulata &&
                  formikFinanziamentoEssere.touched.rata_stipulata
                }
              />
              <Form.Field required>
                <label>Durata mesi</label>
                <Dropdown
                  error={
                    formikFinanziamentoEssere.errors.durata_mesi &&
                    formikFinanziamentoEssere.touched.durata_mesi
                  }
                  placeholder="Durata mesi"
                  fluid
                  selection
                  search
                  options={durata}
                  onChange={(e, data) => handleMesi(data.value)}
                  onBlur={() =>
                    formikFinanziamentoEssere.setFieldTouched("durata_mesi")
                  }
                />
              </Form.Field>
            </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label style={{ fontWeight: 700 }}>Data decorrenza</label>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  clearable
                  cancelLabel="Cancella"
                  clearLabel="Annulla"
                  name="data_decorrenza_essere"
                  value={dataDecorrenzaEssere}
                  onChange={(data) => handleDataDecorrenza(data)}
                  format={"DD-MM-YYYY"}
                />
              </MuiPickersUtilsProvider>
            </Form.Field>
            <Form.Field readOnly>
              <label style={{ fontWeight: 700 }}>Data scadenza</label>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  readOnly
                  name="data_scadenza"
                  value={dataScadenza}
                  onChange={setDataScadenza}
                  format={"DD-MM-YYYY"}
                  TextFieldComponent={TextFieldComponent}
                />
              </MuiPickersUtilsProvider>
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="add circle"
          content="Aggiungi finanziamento in essere"
          onClick={formikFinanziamentoEssere.handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AggiungiFinanziamentoEssere;
