import React, { useEffect, useState } from "react";
import { Menu, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";

import RicercaCollaboratore from "../components/RicercaCollaboratore";
import RicercaProdotto from "../components/RicercaProdotto";
import RicercaDate from "../components/RicercaDate";
import RicercaAmministrazione from '../components/RicercaAmministrazione'
import authService from "../../services/authService";
import Loader from "react-loader-spinner";

const PaginaProduzione = () => {
  const [activeItem, setActiveItem] = useState("collaboratore");
  const { isLoading, sendRequest } = useHttpClient();
  const [produzione, setProduzione] = useState();
  const token = authService.getCurrentToken();

  useEffect(() => {
    const ottieniProduzione = async () => {
      try {
        const info = await sendRequest(
          process.env.REACT_APP_BASE_URL + `/finanziamenti/produzione`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setProduzione(info);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniProduzione();
  }, [sendRequest, token]);

  const handleItemClick = (e, { id }) => setActiveItem(id);

  return (
    <React.Fragment>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {!isLoading && produzione && (
        <div>
          <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
            <div
              className="card-header border-0"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <h3 className="text-center">Produzione</h3>
            </div>
            <Menu fluid pointing secondary stackable>
              <Menu.Item
                name="Cerca per Collaboratore"
                id="collaboratore"
                active={activeItem === "collaboratore"}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="Cerca per data"
                id="data"
                active={activeItem === "data"}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="Cerca per prodotto"
                id="prodotto"
                active={activeItem === "prodotto"}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="Contatti per amministrazione"
                id="amministrazione"
                active={activeItem === "amministrazione"}
                onClick={handleItemClick}
              />
            </Menu>
            <div className="card-body">
              <Segment attached="bottom">
                <div className="row"></div>
                {!isLoading && produzione && activeItem === "collaboratore" && (
                  <RicercaCollaboratore finanziamenti={produzione} />
                )}
                {!isLoading && produzione && activeItem === "data" && (
                  <RicercaDate finanziamenti={produzione} />
                )}
                {!isLoading && produzione && activeItem === "prodotto" && (
                  <RicercaProdotto finanziamenti={produzione} />
                )}
                {!isLoading && activeItem === "amministrazione" && (
                  <RicercaAmministrazione />
                )}
              </Segment>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PaginaProduzione;
