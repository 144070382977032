import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Header, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useFormik } from "formik";

const SelezioneAmministrazione = (props) => {
  const [amministrazioni, setAmministrazioni] = useState();
  const [
    amministrazioneSelezionata,
    setAmministrazioneSelezionata,
  ] = useState();
  const [dataAssunzione, setDataAssunzione] = useState(new Date());
  const { sendRequest, isLoading } = useHttpClient();
  const history = useHistory();
  const token = authService.getCurrentToken();

  const form = useFormik({
    initialValues: {
      sede_lavorativa: "",
    },
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/modificaAmministrazione",
          "POST",
          JSON.stringify({
            id_contatto: props.id_contatto,
            id_amministrazione: amministrazioneSelezionata,
            data_assunzione: dataAssunzione,
            sede_lavorativa: values.sede_lavorativa,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Modifica eseguita correttamente",
          text: responseData,
        }).then(() => {
          history.go();
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });

  useEffect(() => {
    const ottieniAmministrazioni = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/amministrazioni/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const amministrazioni_response = [];
        responseData.forEach((amministrazione) => {
          amministrazioni_response.push({
            key: amministrazione.id_amministrazione,
            value: amministrazione.id_amministrazione,
            text: amministrazione.denominazione,
          });
        });
        setAmministrazioni(amministrazioni_response);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniAmministrazioni();
  }, [sendRequest, token]);

  return (
    <Modal
      onClose={() => props.handleClose()}
      open={true}
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "black",
        name: "close",
      }}
      style={{ position: "relative", height: "auto" }}
      closeOnDimmerClick={false}
    >
      <Modal.Header className="text-center">
        Seleziona una nuova amministrazione
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Header className="text-center">
            Cerca la nuova amministrazione <FontAwesomeIcon icon={faBuilding} />{" "}
          </Header>
          {!isLoading && amministrazioni && (
            <Form>
              <Form.Field required>
                <label style={{ fontWeight: 700 }}>Denominazione</label>
              </Form.Field>
              <Dropdown
                fluid
                selection
                search
                options={amministrazioni}
                onChange={(e, data) =>
                  setAmministrazioneSelezionata(data.value)
                }
              />
              <Form.Group widths="equal">
                <Form.Field className="mt-2" required>
                  <label className="mb-3" style={{ fontWeight: 700 }}>
                    Data assunzione
                  </label>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      fullWidth
                      clearable
                      cancelLabel="Cancella"
                      clearLabel="Annulla"
                      name="data"
                      value={dataAssunzione}
                      onChange={setDataAssunzione}
                      format={"DD-MM-YYYY"}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Field>
                <Form.Field className="mt-2">
                  <label className="mb-3" style={{ fontWeight: 700 }}>
                    Sede lavorativa
                  </label>
                  <Form.Input
                    id="sede_lavorativa"
                    onChange={form.handleChange}
                    value={form.values.sede_lavorativa}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
          <Button
            className="mt-4"
            type="submit"
            positive
            floated="right"
            onClick={form.handleSubmit}
          >
            Conferma
          </Button>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default SelezioneAmministrazione;
