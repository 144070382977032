import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";

import ContactItem from "../../contatti/components/ContactItem";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import authService from "../../services/authService";

import ReactPaginate from "react-paginate";
import "../../App.css";

const RicercaAmministrazione = (props) => {
  const [contatti, setContatti] = useState();
  const [risultatiRicerca, setRisultati] = useState();
  const [amministrazioni, setAmministrazioni] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  //Paginazione
  const elementiPerPagina = 10;
  const [numeroPagine, setNumeroPagine] = useState();
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;
    console.log(offset);

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  //Fine paginazione

  const handleChange = async function (value) {
    formik.setFieldValue("ricerca", value);
    const pagina = {};
    pagina.selected = 0;
    handlePageChange(pagina);
  };

  const formik = useFormik({
    initialValues: {
      ricerca: 1,
    },
  });
  useEffect(() => {
    const ottieniContatti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        console.log(responseData);
        setContatti(responseData.listaContatti);
        setNumeroPagine(
          Math.ceil(responseData.listaContatti.length / elementiPerPagina)
        );
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    const ottieniCollaboratori = async function () {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/amministrazioni",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const amministrazioniArray = [];
        responseData.forEach((amministrazione) => {
          const valore = {
            key: amministrazione.id_amministrazione,
            text: amministrazione.denominazione,
            value: amministrazione.id_amministrazione,
          };
          amministrazioniArray.push(valore);
        });

        setAmministrazioni(amministrazioniArray);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniContatti();
    ottieniCollaboratori();
  }, [sendRequest, token]);

  useEffect(() => {
    if (contatti) {
      const risultati = contatti
        .filter(
          (contatto) => contatto.ref_amministrazione === formik.values.ricerca
        )
        .sort((a, b) => (a.cognome > b.cognome ? 1 : -1));

      setNumeroPagine(Math.ceil(risultati.length / elementiPerPagina));

      console.log(risultati);
      const risultatiPagina = risultati.slice(
        offset,
        offset + elementiPerPagina
      );
      setRisultati(risultatiPagina);
    }
  }, [formik.values.ricerca, contatti, offset]);

  return (
    <div>
      <div>
        <Form unstackable className="mx-3">
          {!isLoading && amministrazioni && (
            <Form.Field>
              <label>Seleziona amministrazione</label>
              <Dropdown
                placeholder="Amministrazione"
                name="amministrazione"
                search
                selection
                options={amministrazioni}
                defaultValue={1}
                onChange={(e, data) => handleChange(data.value)}
                onBlur={() => formik.setFieldTouched("ricerca")}
              />
            </Form.Field>
          )}
        </Form>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />

      {risultatiRicerca && risultatiRicerca.length === 0 ? (
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            <p
              className="col card py-2 px-3 shadow mt-3"
              style={{ fontSize: "14px", borderRadius: "10px" }}
            >
              Non è stato trovato nessun utente per l'amministrazione
              selezionata.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <ul className=" p-0 my-2 mx-3">
            {risultatiRicerca &&
              risultatiRicerca
                .sort((a, b) => (a.cognome > b.cognome ? 1 : -1))
                .map((contatto) => (
                  <ContactItem
                    key={contatto.id_contatto}
                    id_contatto={contatto.id_contatto}
                    nome={contatto.nome}
                    cognome={contatto.cognome}
                    cellulare={contatto.cellulare_1}
                    telefono={contatto.telefono_casa}
                    email={contatto.email}
                    tipo={contatto.tipo}
                  />
                ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RicercaAmministrazione;
