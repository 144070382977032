import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, TextArea, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import authService from "../../../services/authService";
import { useHttpClient } from "../../../util/http-hook";

const notaSchema = Yup.object().shape({
  testo: Yup.string().required("Richiesto"),
});

const ModificaNotaAmministrazione = (props) => {
  const history = useHistory();
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  const formik = useFormik({
    initialValues: {
      testo: decodeURIComponent(props.testo),
    },
    validationSchema: notaSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/amministrazioni/modificaNota/${props.id_nota}`,
          "PATCH",
          JSON.stringify({
            testo: encodeURIComponent(values.testo),
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Nota modificata correttamente",
        }).then(() => {
          history.go(0);
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });
  return (
    <Modal
      onClose={() => props.handleClose()}
      open={true}
      style={{ position: "relative", height: "auto" }}
    >
      <Modal.Header className="text-center">
        Modifica nota <FontAwesomeIcon icon={faClipboard} />{" "}
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description className="text-center">
          <Form>
            <TextArea
              style={{ minHeight: 10 }}
              id="testo"
              placeholder="Testo nota"
              name="testo"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.testo}
            />
          </Form>
        </Modal.Description>
        <Modal.Actions>
          <Button
            positive
            className="float-right mt-md-2"
            onClick={formik.handleSubmit}
            type="submit"
          >
            Modifica Nota
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default ModificaNotaAmministrazione;
