import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React from "react";

import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useHttpClient } from "../../util/http-hook";

const modificaPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Richiesto"),
});

const RecuperaPassword = (props) => {
  const { sendRequest } = useHttpClient();

  const modificaPassword = useFormik({
    initialValues: {
      email: props.email,
    },
    validationSchema: modificaPasswordSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL + `/autenticazione/resetPassword/`,
          "POST",
          JSON.stringify({
            email: values.email,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        Swal.fire({
          icon: "success",
          title:
            "E' stata inviata una nuova password all'indirizzo email registrato.",
        }).then(() => {
          props.handleClose();
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });
  return (
    <Modal
      onClose={() => props.handleClose()}
      open={true}
      style={{ position: "relative", height: "auto" }}
    >
      <Modal.Header className="text-center">
        Recupera Password <FontAwesomeIcon className="ml-2" icon={faKey} />
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form>
            <Form.Input
              label="Inserisci l'email registrata al tuo account"
              value={modificaPassword.values.email}
              placeholder="Email"
              error={
                modificaPassword.errors.email && modificaPassword.touched.email
              }
              onChange={modificaPassword.handleChange}
              onBlur={modificaPassword.handleBlur}
            />
          </Form>
        </Modal.Description>
        <Modal.Actions>
          <Button
            positive
            className="float-right mt-md-2"
            onClick={modificaPassword.handleSubmit}
          >
            Recupera Password
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default RecuperaPassword;
