import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import * as Yup from "yup";
import MomentUtils from "@date-io/moment";
import TextField from '@material-ui/core/TextField';
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Dropdown,
  Form,
  Button,
  Icon,
  Header,
  Divider,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import FinanziamentiEssereItem from "./FinanziamentiEssereItem";

import AggiungiFinanziamentoEssere from "./AggiungiFinanziamentoEssere";
import authService from "../../services/authService";

const finanziamentiSchema = Yup.object().shape({
  ref_contatto: Yup.string().required("Richiesto"),
  ref_operazione: Yup.number().required(),
  rata_stipulata: Yup.number().required("Richiesto"),
  netto_ricavo: Yup.number().required("Richiesto"),
  durata_mesi: Yup.number().required(),
  estinzione_anticipata: Yup.number().required(),
  ref_societa_estinta: Yup.number().required("Richiesto"),
});

const durata = [
  {
    key: 24,
    text: 24 + " mesi",
    value: 24,
  },
  {
    key: 36,
    text: 36 + " mesi",
    value: 36,
  },
  {
    key: 48,
    text: 48 + " mesi",
    value: 48,
  },
  {
    key: 60,
    text: 60 + " mesi",
    value: 60,
  },
  {
    key: 72,
    text: 72 + " mesi",
    value: 72,
  },
  {
    key: 84,
    text: 84 + " mesi",
    value: 84,
  },
  {
    key: 96,
    text: 96 + " mesi",
    value: 96,
  },
  {
    key: 108,
    text: 108 + " mesi",
    value: 108,
  },
  {
    key: 120,
    text: 120 + " mesi",
    value: 120,
  },
];

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />
}

const AggiungiFinanziamento = (props) => {
  const [finanziamentiEssere, setFinanziamentiEssere] = useState([]);
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [open, setOpen] = useState(false);
  const [contatti, setContatti] = useState();
  const [istituti, setIstituti] = useState();
  const [operazioniPossibili, setOperazioniPossibili] = useState();
  const [collaboratori, setCollaboratori] = useState();
  const [dataDecorrenza, setDataDecorrenza] = useState(new Date());
  const [dataScadenza, setDataScadenza] = useState(null);

  const history = useHistory();

  const aggiungiFinanziamentoEssere = (finanziamento) => {
    setFinanziamentiEssere((finanziamentiEssere) => [
      ...finanziamentiEssere,
      finanziamento,
    ]);
  };

 
  const handleDataDecorrenza = (data) => {
    setDataDecorrenza(data);
    const data_scadenza = moment(data).add(
      formikFinanziamento.values.durata_mesi - 1,
      "M"
    );
    setDataScadenza(data_scadenza);
  };

  const handleMesi = (value) => {
    formikFinanziamento.setFieldValue("durata_mesi", value);
    const data_scadenza = moment(dataDecorrenza).add(value - 1, "M");
    setDataScadenza(data_scadenza);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const ottieniContatti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const contatti_response = [];
        responseData.listaContatti.forEach((contatto) => {
          contatti_response.push({
            key: contatto.id_contatto,
            value: contatto.id_contatto,
            text: contatto.cognome + " " + contatto.nome,
          });
        });
        setContatti(contatti_response);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    const ottieniIstituti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/istituti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const istituti_response = [];
        responseData.forEach((istituto) => {
          istituti_response.push({
            key: istituto.id_istituto,
            value: istituto.id_istituto,
            text: istituto.denominazione,
          });
        });
        setIstituti(istituti_response);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    const ottieniCollaboratori = async function () {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/collaboratori",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const collaboratoriArray = [];
        responseData.forEach((collaboratore) => {
          const valore = {
            key: collaboratore.id_collaboratore,
            text: collaboratore.cognome + " " + collaboratore.nome,
            value: collaboratore.id_collaboratore,
          };
          collaboratoriArray.push(valore);
        });

        setCollaboratori(collaboratoriArray);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };

    ottieniCollaboratori();
    ottieniIstituti();

    ottieniContatti();
  }, [sendRequest, token]);

  const formikFinanziamento = useFormik({
    initialValues: {
      ref_contatto: null,
      ref_operazione: "",
      ref_collaboratore: "",
      rata_stipulata: "",
      netto_ricavo: "",
      durata_mesi: "",
      ref_societa_estinta: "",
      estinzione_anticipata: "",
    },
    validationSchema: finanziamentiSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL +
            "/finanziamenti/inserisciFinanziamento",
          "POST",
          JSON.stringify({
            ref_contatto: values.ref_contatto,
            ref_operazione: values.ref_operazione,
            ref_collaboratore: values.ref_collaboratore,
            durata_mesi: values.durata_mesi,
            rata_stipulata: values.rata_stipulata,
            netto_ricavo: values.netto_ricavo,
            ref_societa_estinta: values.ref_societa_estinta,
            data_decorrenza: dataDecorrenza,
            estinzione_anticipata: values.estinzione_anticipata,
            data_scadenza: dataScadenza,
            finanziamentiEssere,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Finanziamento inserito correttamente",
        }).then(() => {
          history.go(0);
          props.closeModel();
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });

  useEffect(() => {
    const ottieniOperazioniPossibili = async (id_contatto) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/operazioni/possibili",
          "POST",
          JSON.stringify({
            id_contatto,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );

        const operazioni_response = [];
        responseData.forEach((operazione) => {

          operazioni_response.push({
            key: operazione["operazione.id_operazione"],
            value: operazione["operazione.id_operazione"],
            text: operazione["operazione.descrizione_operazione"],
          });
        });
        setOperazioniPossibili(operazioni_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };

    if (formikFinanziamento.values.ref_contatto) {
      ottieniOperazioniPossibili(formikFinanziamento.values.ref_contatto);
    }
  }, [formikFinanziamento.values.ref_contatto, sendRequest, token]);

  return (
    <React.Fragment>
      {open && (
        <AggiungiFinanziamentoEssere
          handleClose={handleClose}
          aggiungiFinanziamento={aggiungiFinanziamentoEssere}
        />
      )}
      <div>
        <div style={{ fontSize: "110%" }}>
          {contatti && istituti && (
            <Form>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label>Cliente</label>
                  <Dropdown
                    error={
                      formikFinanziamento.errors.ref_contatto &&
                      formikFinanziamento.touched.ref_contatto
                    }
                    placeholder="Contatto"
                    fluid
                    selection
                    search
                    options={contatti}
                    onChange={(e, data) =>
                      formikFinanziamento.setFieldValue(
                        "ref_contatto",
                        data.value
                      )
                    }
                    onBlur={() =>
                      formikFinanziamento.setFieldTouched("ref_contatto")
                    }
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Collaboratore</label>
                  <Dropdown
                    error={
                      formikFinanziamento.errors.ref_collaboratore &&
                      formikFinanziamento.touched.ref_collaboratore
                    }
                    placeholder="Collaboratore"
                    fluid
                    selection
                    search
                    options={collaboratori}
                    onChange={(e, data) =>
                      formikFinanziamento.setFieldValue(
                        "ref_collaboratore",
                        data.value
                      )
                    }
                    onBlur={() =>
                      formikFinanziamento.setFieldTouched("ref_collaboratore")
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field required>
                  <label>Operazione</label>
                  <Dropdown
                    error={
                      formikFinanziamento.errors.ref_operazione &&
                      formikFinanziamento.touched.ref_operazione
                    }
                    placeholder="Operazione"
                    fluid
                    selection
                    search
                    options={operazioniPossibili}
                    onChange={(e, data) =>
                      formikFinanziamento.setFieldValue(
                        "ref_operazione",
                        data.value
                      )
                    }
                    onBlur={() =>
                      formikFinanziamento.setFieldTouched("ref_operazione")
                    }
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Società</label>
                  <Dropdown
                    error={
                      formikFinanziamento.errors.ref_societa_estinta &&
                      formikFinanziamento.touched.ref_societa_estinta
                    }
                    placeholder="Società"
                    fluid
                    selection
                    search
                    options={istituti}
                    onChange={(e, data) =>
                      formikFinanziamento.setFieldValue(
                        "ref_societa_estinta",
                        data.value
                      )
                    }
                    onBlur={() =>
                      formikFinanziamento.setFieldTouched("ref_societa_estinta")
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="Rata stipulata"
                  id="rata_stipulata"
                  icon="euro"
                  value={formikFinanziamento.values.rata_stipulata}
                  onChange={formikFinanziamento.handleChange}
                  onBlur={formikFinanziamento.handleBlur}
                  error={
                    formikFinanziamento.errors.rata_stipulata &&
                    formikFinanziamento.touched.rata_stipulata
                  }
                />
                <Form.Field required>
                  <label>Durata mesi</label>
                  <Dropdown
                    error={
                      formikFinanziamento.errors.durata_mesi &&
                      formikFinanziamento.touched.durata_mesi
                    }
                    placeholder="Durata mesi"
                    fluid
                    selection
                    search
                    options={durata}
                    onChange={(e, data) => handleMesi(data.value)}
                    onBlur={() =>
                      formikFinanziamento.setFieldTouched("durata_mesi")
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label style={{ fontWeight: 700 }}>Data decorrenza</label>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      fullWidth
                      clearable
                      cancelLabel="Cancella"
                      clearLabel="Annulla"
                      name="data_decorrenza"
                      value={dataDecorrenza}
                      onChange={(data) => {
                        handleDataDecorrenza(data);
                      }}
                      format={"DD-MM-YYYY"}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Field>
                <Form.Field readOnly>
                  <label style={{ fontWeight: 700 }}>Data scadenza</label>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      fullWidth
                      readOnly
                      name="data_scadenza"
                      value={dataScadenza}
                      onChange={setDataScadenza}
                      format={"DD-MM-YYYY"}
                      TextFieldComponent={TextFieldComponent}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="Netto ricavo"
                  id="netto_ricavo"
                  icon="euro"
                  value={formikFinanziamento.values.netto_ricavo}
                  onChange={formikFinanziamento.handleChange}
                  onBlur={formikFinanziamento.handleBlur}
                  error={
                    formikFinanziamento.errors.netto_ricavo &&
                    formikFinanziamento.touched.netto_ricavo
                  }
                />
                <Form.Input
                  required
                  label="Importo estinzione anticipata"
                  id="estinzione_anticipata"
                  value={formikFinanziamento.values.estinzione_anticipata}
                  onChange={formikFinanziamento.handleChange}
                  onBlur={formikFinanziamento.handleBlur}
                  error={
                    formikFinanziamento.errors.estinzione_anticipata &&
                    formikFinanziamento.touched.estinzione_anticipata
                  }
                  icon="euro"
                />
              </Form.Group>
              <Divider horizontal className="my-4">
                <Header as="h4">
                  <Icon name="list" />
                  Finanziamenti in essere
                </Header>
              </Divider>
              <ul className=" p-0 my-2 mx-3">
                {finanziamentiEssere &&
                  finanziamentiEssere.map((finanziamento, i) => (
                    <FinanziamentiEssereItem
                      key={i}
                      finanziamento={finanziamento}
                    />
                  ))}
              </ul>
              <br></br>
              <Button.Group floated="right">
                <Button
                  floated="right"
                  icon
                  onClick={() => setOpen(true)}
                  type="button"
                >
                  <Icon name="add circle" /> Finanziamento in essere
                </Button>
                <Button.Or text="O" />
                <Button
                  floated="right"
                  positive
                  icon
                  onClick={formikFinanziamento.handleSubmit}
                  type="submit"
                >
                  <Icon name="save" /> Salva
                </Button>
              </Button.Group>
            </Form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AggiungiFinanziamento;
