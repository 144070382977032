import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Dropdown, Form, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";
import lista_province from "../../services/province.json";
import lista_comuni from "../../services/comuni.json";

const istitutiSchema = Yup.object().shape({
  denominazione: Yup.string().required("Richiesto"),
});

const AggiungiIstituto = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [comuni, setComuni] = useState();

  const handleChangeProvincia = (data) => {
    istituto.setFieldValue("provincia", data.value);
    lista_comuni.forEach((provincia) => {
      if (provincia.id === data.value) {
        setComuni(provincia.comuni);
      }
    });
  };

  const istituto = useFormik({
    initialValues: {
      denominazione: "",
      indirizzo: "",
      citta: "",
      provincia: "",
      cap: "",
      recapito_1: "",
      recapito_2: "",
      email: "",
      email_conteggio_estintivo: "",
    },
    validationSchema: istitutiSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/istituti/aggiungiIstituto",
          "POST",
          JSON.stringify({
            indirizzo: values.indirizzo,
            citta: values.citta,
            provincia: values.provincia,
            cap: values.cap,
            recapito_1: values.recapito_1,
            recapito_2: values.recapito_2,
            email: values.email,
            email_conteggio_estintivo: values.email_conteggio_estintivo,
            denominazione: values.denominazione,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Istituto caricato correttamente",
        }).then(() => {
          props.aggiungiIstituto(responseData);
          props.handleClose();
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    },
  });

  return (
    <Form unstackable>
      <Form.Input
        required
        label="Denominazione"
        id="denominazione"
        error={istituto.errors.denominazione && istituto.touched.denominazione}
        onChange={istituto.handleChange}
        onBlur={istituto.handleBlur}
        value={istituto.values.denominazione}
      />
      <Form.Input
        label="Indirizzo"
        id="indirizzo"
        error={istituto.errors.indirizzo && istituto.touched.indirizzo}
        onChange={istituto.handleChange}
        onBlur={istituto.handleBlur}
        value={istituto.values.indirizzo}
      />
      <Form.Group widths="equal">
        <Form.Field >
          <label>Provincia</label>
          <Dropdown
            error={istituto.errors.provincia && istituto.touched.provincia}
            placeholder="Provincia"
            fluid
            selection
            search
            options={lista_province}
            onChange={(e, data) => handleChangeProvincia(data)}
            onBlur={() => istituto.setFieldTouched("provincia")}
          />
        </Form.Field>
        <Form.Field >
          <label>Città</label>
          <Dropdown
            error={istituto.errors.citta && istituto.touched.citta}
            placeholder="Citta"
            fluid
            selection
            search
            options={comuni}
            onChange={(e, data) => istituto.setFieldValue("citta", data.value)}
            onBlur={() => istituto.setFieldTouched("citta")}
          />
        </Form.Field>
        <Form.Input
          label="CAP"
          id="cap"
          className="mb-3 mb-md-0"
          error={istituto.errors.cap && istituto.touched.cap}
          onChange={istituto.handleChange}
          onBlur={istituto.handleBlur}
          value={istituto.values.cap}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Recapito 1"
          id="recapito_1"
          className="mb-3 mb-md-0"
          error={istituto.errors.recapito_1 && istituto.touched.recapito_1}
          onChange={istituto.handleChange}
          onBlur={istituto.handleBlur}
          value={istituto.values.recapito_1}
        />
        <Form.Input
          label="Recapito 2"
          id="recapito_2"
          className="mb-3 mb-md-0"
          error={istituto.errors.recapito_2 && istituto.touched.recapito_2}
          onChange={istituto.handleChange}
          onBlur={istituto.handleBlur}
          value={istituto.values.recapito_2}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Email"
          id="email"
          className="mb-3 mb-md-0"
          error={istituto.errors.email && istituto.touched.email}
          onChange={istituto.handleChange}
          onBlur={istituto.handleBlur}
          value={istituto.values.email}
        />
        <Form.Input
          label="Email conteggio estintivo"
          id="email_conteggio_estintivo"
          error={
            istituto.errors.email_conteggio_estintivo &&
            istituto.touched.email_conteggio_estintivo
          }
          onChange={istituto.handleChange}
          onBlur={istituto.handleBlur}
          value={istituto.values.email_conteggio_estintivo}
        />
      </Form.Group>
      <Button
        className="mt-3 mt-md-0"
        floated="right"
        positive
        icon
        onClick={istituto.handleSubmit}
      >
        <Icon name="add circle" /> Aggiungi finanziamento
      </Button>
    </Form>
  );
};

export default AggiungiIstituto;
