import {
  faCalendarDay,
  faClipboard,
  faHandHoldingUsd,
  faPollH,
  faSearchPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import moment from "moment";
import { Form, Icon, Label } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

const OpportunitaItem = (props) => {
  const data = moment(props.data).format("DD/MM/YYYY HH:mm");




  const history = useHistory();

  return (
    <React.Fragment>
      {new Date() >= new Date(props.data) && !props.completata ? (
        <div
          className="card shadow col-sm-9 mx-auto mt-4"
          style={
            props.opportunita.contatto.tipo.toLowerCase() === "cliente"
              ? {
                  border: "none",
                  backgroundColor: "#4169E1",
                  color: "white",
                  borderRadius: "10px",
                }
              : {
                  border: "none",
                  backgroundColor: "#FF4500",
                  borderRadius: "10px",
                }
          }
        >
          <div className="mb-2 mt-2">
            <span style={{ fontWeight: 600, fontSize: "130%" }}>
              {props.nome_attivita}
            </span>{" "}
            <Icon name={props.icona_attivita}></Icon>
            <FontAwesomeIcon
              className="float-right"
              role="button"
              icon={faSearchPlus}
              size="lg"
              onClick={() =>
                history.push(
                  `/dettagliOpportunita/${props.ref_contatto}/${props.id_opportunita}`
                )
              }
            />
          </div>
          <div className="mb-3 mt-1" style={{ fontSize: "110%" }}>
            <FontAwesomeIcon icon={faUser} className="mr-1" />
            <span style={{ fontWeight: 600 }}>
              {props.opportunita.contatto.tipo}:
            </span>{" "}
            {props.cognome} {props.nome}
          </div>
          {props.opportunita.operazione && (
            <div className="mb-1">
              <FontAwesomeIcon icon={faHandHoldingUsd} />
              <label style={{ fontWeight: 600 }} className="ml-1">
                {props.opportunita.operazione.descrizione_operazione}
              </label>{" "}
            </div>
          )}

          <div className="mb-1">
            <FontAwesomeIcon icon={faCalendarDay} />
            <label style={{ fontWeight: 600 }} className="ml-1">
              Data e ora:
            </label>{" "}
            {data}
          </div>
          <div className="mb-3">
            <div className="col-12 p-0">
              <FontAwesomeIcon icon={faClipboard} />
              <label style={{ fontWeight: 600 }} className="ml-1">
                Note:
              </label>
            </div>
            <Form>
              <TextareaAutosize
                placeholder="Testo nota"
                name="testo"
                type="text"
                readOnly
                value={props.note}
              />
            </Form>
          </div>
          {props.esito ? (
            <div className="mb-2">
              <div className="col-12 p-0">
                <FontAwesomeIcon icon={faPollH} />
                <label style={{ fontWeight: 600 }} className="ml-1">
                  Esito:
                </label>
              </div>
              <Form>
                <TextareaAutosize
                  placeholder="Testo nota"
                  name="testo"
                  type="text"
                  readOnly
                  value={props.esito}
                />
              </Form>
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className="card shadow col-sm-9 mx-auto mt-4"
          style={{ border: "none", borderRadius: "10px" }}
        >
          <div className="mb-2 mt-2">
            <span style={{ fontWeight: 600, fontSize: "130%" }}>
              {props.nome_attivita}
            </span>{" "}
            <Icon name={props.icona_attivita}></Icon>
            <FontAwesomeIcon
              className="float-right"
              role="button"
              icon={faSearchPlus}
              size="lg"
              onClick={() =>
                history.push(
                  `/dettagliOpportunita/${props.ref_contatto}/${props.id_opportunita}`
                )
              }
            />
          </div>
          <div className="mb-3 mt-1" style={{ fontSize: "110%" }}>
            <FontAwesomeIcon icon={faUser} className="mr-1" />
            <span style={{ fontWeight: 600 }}>
              {props.opportunita.contatto.tipo}:
            </span>{" "}
            {props.cognome} {props.nome}
          </div>
          <div className="mb-1">
            <FontAwesomeIcon icon={faCalendarDay} />
            <label style={{ fontWeight: 600 }} className="ml-1">
              Data e ora:
            </label>{" "}
            {data}
          </div>
          <div className="mb-3">
            <div className="col-12 p-0">
              <FontAwesomeIcon icon={faClipboard} />
              <label style={{ fontWeight: 600 }} className="ml-1">
                Note:
              </label>
              {!props.completata ? (
                <Label color="blue" className="float-right mb-2">
                  Programmata
                  <Icon name="checked calendar" className="ml-1 mr-0" />
                </Label>
              ) : null}
            </div>
            <Form>
              <TextareaAutosize
                placeholder="Testo nota"
                name="testo"
                type="text"
                readOnly
                value={props.note}
              />
            </Form>
          </div>
          {props.esito ? (
            <div className="mb-2">
              <div className="col-12 p-0">
                <FontAwesomeIcon icon={faPollH} />
                <label style={{ fontWeight: 600 }} className="ml-1">
                  Esito:
                </label>
              </div>
              <Form>
                <TextareaAutosize
                  placeholder="Testo nota"
                  name="testo"
                  type="text"
                  readOnly
                  value={props.esito}
                />
              </Form>
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default OpportunitaItem;
