import { useFormik } from "formik";
import React from "react";
import { Button, Checkbox, Icon, Label, Table } from "semantic-ui-react";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";

/*
Utilizzo gli id_operazioni del db,
1 => CQS,
2 => DLG,
3 => Doppia DLG
*/

const GestisciOperazioni = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  const operazioni = useFormik({
    initialValues: {
      cessione: props.operazioni.indexOf(1) !== -1,
      delega: props.operazioni.indexOf(2) !== -1,
      doppia_delega: props.operazioni.indexOf(3) !== -1,
    },
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL + "/operazioni/modificaOperazioni",
          "POST",
          JSON.stringify({
            cessione: values.cessione,
            delega: values.delega,
            doppia_delega: values.doppia_delega,
            ref_amministrazione: props.ref_amministrazione,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazioni modificate correttamente",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    },
  });
  return (
    <div>
      <Label circular color={"blue"} size="big">
        Operazioni disponibili
      </Label>
      <Table compact celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Attiva</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Cessione</Table.Cell>
            <Table.Cell textAlign="center" verticalAlign="middle">
              <Checkbox
                toggle
                defaultChecked={props.operazioni.indexOf(1) !== -1}
                onChange={(e, data) =>
                  operazioni.setFieldValue("cessione", data.checked)
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Delega</Table.Cell>
            <Table.Cell textAlign="center" verticalAlign="middle">
              <Checkbox
                toggle
                defaultChecked={props.operazioni.indexOf(2) !== -1}
                onChange={(e, data) =>
                  operazioni.setFieldValue("delega", data.checked)
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Doppia Delega</Table.Cell>
            <Table.Cell textAlign="center" verticalAlign="middle">
              <Checkbox
                toggle
                defaultChecked={props.operazioni.indexOf(3) !== -1}
                onChange={(e, data) =>
                  operazioni.setFieldValue("doppia_delega", data.checked)
                }
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Button positive onClick={operazioni.handleSubmit}>
        Salva modifiche <Icon className="ml-1" name="save" />
      </Button>
    </div>
  );
};

export default GestisciOperazioni;
