import React, { Component } from "react";

import FinanziamentiItem from "../components/DettagliFinanziamenti/FinanziamentiItem";
import NoteItem from "../../shared/components/NoteItem";
import OpportunitaItem from "../components/DettagliOpportunità/OpportunitaContattoItem";
import { Form, Label } from "semantic-ui-react";
import moment from "moment";

export default class StampaContatto extends Component {
  render() {
    const { infoContatto } = this.props;

    return (
      <React.Fragment>
        <div className="card my-2 mx-3">
          <div>
            <div
              className="card-header border-0"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <div className="row">
                <div
                  className="col-sm-12 col-lg-4"
                  style={{ fontWeight: 700, fontSize: "140%" }}
                >
                  {infoContatto.infoPersonali.cognome}{" "}
                  {infoContatto.infoPersonali.nome}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div>
                <div>
                  <div className="text-center mb-2">
                    <Label size="big">Informazioni personali</Label>
                  </div>

                  <Form>
                    <Form.Input
                      label="Codice fiscale"
                      value={infoContatto.infoPersonali.cf}
                    />
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Nome"
                        id="nome"
                        value={infoContatto.infoPersonali.nome}
                      />
                      <Form.Input
                        label="Cognome"
                        id="cognome"
                        value={infoContatto.infoPersonali.cognome}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Data di nascita"
                        value={moment(
                          infoContatto.infoPersonali.data_nascita
                        ).format("DD/MM/YYYY")}
                      />
                      <Form.Input
                        label="Città di nascita"
                        id="citta_nascita"
                        value={infoContatto.infoPersonali.citta_nascita}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Città di residenza "
                        value={infoContatto.infoPersonali.citta_residenza}
                      />
                      <Form.Input
                        label="Provincia di residenza"
                        id="provincia_residenza"
                        value={infoContatto.infoPersonali.provincia_residenza}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Indirizzo di residenza "
                        id="indirizzo_residenza"
                        value={infoContatto.infoPersonali.indirizzo_residenza}
                      />
                      <Form.Input
                        label="CAP residenza"
                        id="cap_residenza"
                        value={infoContatto.infoPersonali.cap_residenza}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Cellulare 1"
                        id="cellulare_1"
                        value={infoContatto.infoPersonali.cellulare_1}
                      />
                      <Form.Input
                        label="Cellulare 2"
                        id="cellulare_2"
                        value={infoContatto.infoPersonali.cellulare_2}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Telefono casa"
                        id="telefono_casa"
                        value={infoContatto.infoPersonali.telefono_casa}
                      />
                      <Form.Input
                        label="Telefono ufficio"
                        id="telefono_ufficio"
                        value={infoContatto.infoPersonali.telefono_ufficio}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Email"
                        id="email"
                        value={infoContatto.infoPersonali.email}
                      />
                      <Form.Input
                        label="Tipo"
                        value={infoContatto.infoPersonali.tipo}
                      />
                    </Form.Group>
                  </Form>
                </div>

                <div className="mt-4">
                  <div className="text-center mb-3">
                    <Label size="big">Informazioni Amministrazione</Label>
                  </div>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Denominazione"
                        value={infoContatto.infoLavorative.denominazione}
                      />
                      <Form.Input
                        label="Tipologia"
                        value={
                          infoContatto.infoLavorative.tipologia_amministrazione
                            .descrizione_tipologia
                        }
                      />
                      <Form.Input
                        label="Data assunzione"
                        value={moment(
                          infoContatto.infoPersonali.data_assunzione
                        ).format("DD/MM/YYYY")}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div>
                  <div className="text-center mb-3">
                    <Label size="big">Informazioni finanziamenti</Label>
                  </div>
                  {infoContatto.infoFinanziamenti.length === 0 ? (
                    <div className="row mx-0 justify-content-center">
                      <div className="col-12">
                        <p
                          className="col card py-2 px-3 shadow mt-3"
                          style={{ fontSize: "14px", borderRadius: "10px" }}
                        >
                          Al momento non ci sono finanziamenti per questo
                          contatto.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ul className=" p-0 my-2 mx-3">
                        {infoContatto.infoFinanziamenti
                          .sort((a, b) =>
                            a.data_decorrenza < b.data_decorrenza ? 1 : -1
                          )
                          .map((finanziamento) => (
                            <FinanziamentiItem
                              finanziamento={finanziamento}
                              key={finanziamento.id_finanziamento}
                            />
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <div className="text-center mb-3">
                    <Label size="big">Note</Label>
                  </div>
                  {infoContatto.infoNote.length === 0 ? (
                    <div className="row mx-0 justify-content-center">
                      <div className="col-12">
                        <p
                          className="col card py-2 px-3 shadow mt-3"
                          style={{ fontSize: "14px", borderRadius: "10px" }}
                        >
                          Al momento non ci sono note per questo contatto.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ul className=" p-0 my-2 mx-3">
                        {infoContatto.infoNote
                          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                          .map((nota) => (
                            <NoteItem
                              key={nota.id_nota}
                              data={nota.createdAt}
                              testo={
                                nota.testo ? decodeURIComponent(nota.testo) : ""
                              }
                            />
                          ))}
                      </ul>
                      <br></br>
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <div className="text-center mb-3">
                    <Label size="big">Opportunità</Label>
                  </div>
                  {infoContatto.infoOpportunita.length === 0 ? (
                    <div className="row mx-0 justify-content-center">
                      <div className="col-12">
                        <p
                          className="col card py-2 px-3 shadow mt-3"
                          style={{ fontSize: "14px", borderRadius: "10px" }}
                        >
                          Al momento non ci sono opportunita per questo
                          contatto.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <ul className=" p-0 my-3 mx-3">
                      {infoContatto.infoOpportunita
                        .sort((a, b) =>
                          a.data_opportunita < b.data_opportunita ? 1 : -1
                        )
                        .map((opportunita) => (
                          <OpportunitaItem
                            key={opportunita.id_opportunita}
                            data={opportunita.data_opportunita}
                            nome_attivita={
                              opportunita.attivitum.descrizione_attivita
                            }
                            icona_attivita={opportunita.attivitum.icona}
                            completata={opportunita.completata}
                            note={
                              opportunita.note_opportunita
                                ? decodeURIComponent(
                                    opportunita.note_opportunita
                                  )
                                : ""
                            }
                            esito={
                              opportunita.esito_opportunita
                                ? decodeURIComponent(
                                    opportunita.esito_opportunita
                                  )
                                : ""
                            }
                          />
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
