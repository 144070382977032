import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import AggiungiUtente from "./AggiungiUtente";
import UtenteItem from "./UtenteItem";

const UtenteList = (props) => {

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const aggiungiUtente = (amministrazione) => {
    props.utenti.push(amministrazione)
  }

  
  return (
    <div>
      {open && (
        <AggiungiUtente handleClose={handleClose} aggiungiUtente={aggiungiUtente} />
      )}
      <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
        <div
          className="card-header border-0"
          style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
        >
          <h3 className="mb-0 text-center">Utenti</h3>
        </div>
        <div className="card-body">
          <label
            className="float-left align-middle mt-2 col-md-2 col-lg-2 col-sm-12  p-0"
            style={{ fontWeight: 500 }}
            role="button"
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Aggiungi utente
          </label>
        </div>
      </div>
      {props.utenti.length === 0 ? (
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            <p
              className="col card py-2 px-3 shadow mt-3"
              style={{ fontSize: "14px", borderRadius: "10px" }}
            >
              Al momento non ci sono utenti, aggiungili tramite l'apposito tasto
              per visualizzarli qui.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <ul className=" p-0 my-2 mx-3">
            {props.utenti
              .sort((a, b) => (a.createdat > b.createdat ? 1 : -1))
              .map((utente) => (
                <UtenteItem key={utente.email} utente={utente} />
              ))}
          </ul>
          <br></br>
        </div>
      )}
    </div>
  );
};

export default UtenteList;
