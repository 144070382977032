import React, { useRef, useState, useEffect } from "react";
import { Button } from "semantic-ui-react";

import "./ImageUpload.css";

const DocumentoUpload = (props) => {
  const [file, setFile] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];

      setFile(pickedFile);

      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }

    props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div>
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".pdf"
        onChange={pickedHandler}
        onClick={props.onClick}
        onInputCapture={props.onChange}
      />
      <Button color="blue" icon="file" label={props.label} onClick={pickImageHandler}/>

      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default DocumentoUpload;
