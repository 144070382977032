import {
  faHandHoldingUsd,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import ListaFinanziamenti from "../components/ListaFinanziamenti";
import AggiungiFinanziamento from "../../shared/components/AggiungiFinanziamento";
import authService from "../../services/authService";
import AggiungiFinanziamentoEssereSingolo from "../../shared/components/AggiungiFinanziamentoEssereSingolo";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "../../App.css";

const PaginaFinanziamenti = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [open, setOpen] = useState(false);
  const [visibile, setVisibile] = useState(false);
  const [finanziamenti, setFinanziamenti] = useState();
  const [risultatiRicerca, setRisultati] = useState();
  const token = authService.getCurrentToken();

  //Paginazione
  const [numeroPagine, setNumeroPagine] = useState();
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);
  const elementiPerPagina = 10;

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  //Fine paginazione

  const formik = useFormik({
    initialValues: {
      ricerca: "",
    },
  });

  const aggiungiFinanziamento = (finanziamento) => {
    setFinanziamenti((finanziamenti) => [...finanziamenti, finanziamento]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVisibile = () => {
    setVisibile(false);
  };

  useEffect(() => {
    const ottieniFinanziamenti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/finanziamenti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );

        setFinanziamenti(responseData);
        setNumeroPagine(Math.ceil(responseData.length / elementiPerPagina));
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniFinanziamenti();
  }, [sendRequest, token]);

  useEffect(() => {
    if (finanziamenti) {
      const risultati = finanziamenti
        .filter(
          (finanziamento) =>
            finanziamento.contatto &&
            finanziamento.contatto.cognome
              .toLowerCase()
              .includes(formik.values.ricerca.toLowerCase())
        )
        .sort((a, b) => (a.data_decorrenza < b.data_decorrenza ? 1 : -1));
      if (risultati.length) {
        setNumeroPagine(Math.ceil(risultati.length / elementiPerPagina));
      }

      if (formik.values.ricerca) {
        const pagina = {};
        pagina.selected = 0;
        handlePageChange(pagina);
      }

      const risultatiPagina = risultati.slice(
        offset,
        offset + elementiPerPagina
      );
      setRisultati(risultatiPagina);
    }
  }, [formik.values.ricerca, finanziamenti, offset]);
  return (
    <React.Fragment>
      {visibile && (
        <AggiungiFinanziamentoEssereSingolo
          aggiungiFinanziamento={aggiungiFinanziamento}
          handleClose={handleVisibile}
        />
      )}
      <Modal
        closeIcon={{
          style: { top: "1.0535rem", right: "1rem" },
          color: "black",
          name: "close",
        }}
        closeOnDimmerClick={false}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Aggiungi nuovo finanziamento{" "}
          <FontAwesomeIcon icon={faHandHoldingUsd} />{" "}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <AggiungiFinanziamento
              closeModel={handleClose}
              aggiungiFinanziamento={aggiungiFinanziamento}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
        <div
          className="card-header border-0"
          style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
        >
          <h3 className="mb-0 text-center">Finanziamenti</h3>
        </div>

        <div className="card-body">
          <label
            className="float-left align-middle mt-2 col-md-2 col-lg-2 col-sm-12  p-0"
            style={{ fontWeight: 500 }}
            role="button"
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Aggiungi finanziamento cliente
          </label>
          <label
            className="float-left align-middle mt-2 col-md-4 col-lg-4 col-sm-12  p-0"
            style={{ fontWeight: 500 }}
            role="button"
            onClick={() => setVisibile(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Aggiungi finanziamento in essere lead
          </label>
          <form>
            <div className="form-group row col-md-6 col-lg-6 col-sm-12 ">
              <label
                htmlFor="ricerca"
                className="col-sm-12 col-md-3 col-lg-3 mt-2 p-0 text-lg-right mr-1"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-1  p-0"
                ></FontAwesomeIcon>
                Ricerca
              </label>
              <div className="col-sm-8 ml-auto p-0">
                <input
                  id="ricerca"
                  className="form-control text-center"
                  name="ricerca"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ricerca}
                  style={{ borderRadius: "500rem" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      {!isLoading && risultatiRicerca && (
        <ListaFinanziamenti finanziamenti={risultatiRicerca} />
      )}
    </React.Fragment>
  );
};

export default PaginaFinanziamenti;
